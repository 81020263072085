import { createSelector } from 'reselect';

import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';

export const selectNormalizedExplodeStates = () => createSelector(
  selectEntities(),
  (entities) => entities.get(EK.EXPLODE_STATES.state)
);
export const selectNormalizedExplodeStatesList = () => createSelector(
  selectNormalizedExplodeStates(),
  explodeStates => explodeStates.toList()
);
