import EntityRecord, { VALIDATORS, FILTERS } from '../../modules/entities/model';

export const ALLOWANCES = {
  INSULATION_CODE: /^(.{0,255})$/,
};

class InsulationSpec extends EntityRecord({
  insulationCode: {
    value: null,
    handleCopy: (entity) => `${entity.insulationCode} COPY`,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      VALIDATORS.IS_REQUIRED_STRING(entity.insulationCode) &&
      ALLOWANCES.INSULATION_CODE.test(entity.insulationCode),
    validateError: 'An insulation code is required',
    filter: FILTERS.STRING,
  },
  appearance: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'appearanceId',
    validate: (entity) => VALIDATORS.IS_REQUIRED_ENTITY(entity.appearance),
    validateError: 'An appearance is required',
    filter: FILTERS.ENTITY,
  },
  insulationFamily: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'insulationFamilyId',
    validate: (entity) =>
      VALIDATORS.IS_REQUIRED_ENTITY(entity.insulationFamily),
    validateError: 'An insulation is required',
    filter: FILTERS.ENTITY,
  },
}) {
  get primary() {
    return this.insulationCode;
  }
  get primaryPlaceholder() {
    return 'No Name';
  }
  get secondary() {
    return this.insulationFamily.name;
  }
  get secondaryPlaceholder() {
    return '--';
  }
  get tertiary() {
    return this.appearance && this.appearance.color;
  }
}

export default InsulationSpec;
