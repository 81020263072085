import { toast } from 'react-toastify';

import EK from '../keys';

import api from '../../modules/api/actions';
import { REQUEST_TYPES } from '../../modules/api/constants';

import {
  createNoteBlockNoteTypes,
  editNoteBlockNoteTypes,
  deleteNoteBlockNoteTypes,
} from './constants';
import schema from './schema';

const client = api(schema, EK.NOTE_BLOCK_NOTES);

export const processCreateNoteBlockNote = (profileId, blockId, data) => client(REQUEST_TYPES.POST, 'plural', createNoteBlockNoteTypes, `/note_profiles/${profileId}/note_blocks/${blockId}/notes`, {
  data: {
    noteBlockNotes: [data],
  },
  mutateResponse: ({ noteBlockNotes = [] }) => ({
    noteBlockNotes: noteBlockNotes.map(note => ({ ...note, noteBlock: `${blockId}`, noteBlockId: `${blockId}` }))
  }),
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to add note',
  },
});

export const processEditNoteBlockNote = (profileId, blockId, noteBlockNoteId, data) => client(REQUEST_TYPES.PATCH, 'single', editNoteBlockNoteTypes, `/note_profiles/${profileId}/note_blocks/${blockId}/notes/${noteBlockNoteId}`, {
  data,
  mutateResponse: ({ noteBlockNote = {} }) => ({
    noteBlockNote: ({
      ...noteBlockNote,
      noteBlock: `${blockId}`,
      noteBlockId: `${blockId}`,
    })
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save note',
  },
});

export const processDeleteNoteBlockNote = (profileId, blockId, noteBlockNoteId) => client(REQUEST_TYPES.DELETE, 'single', deleteNoteBlockNoteTypes, `/note_profiles/${profileId}/note_blocks/${blockId}/notes/${noteBlockNoteId}?with_full_detail=true`, {
  deleteKey: EK.NOTE_BLOCK_NOTES.state,
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to remove note',
  },
});