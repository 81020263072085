import { toast } from 'react-toastify';

import insulationInstanceClient from './client';

import { REQUEST_TYPES } from '../../modules/api/constants';

import { saveInsulationInstancesTypes } from './constants';

export const processSaveInsulationInstances = (insulationFamilyId, data) => insulationInstanceClient(REQUEST_TYPES.PATCH, 'plural', saveInsulationInstancesTypes, `/piping/insulation_families/${insulationFamilyId}?diff_result=true`, {
  data: { insulationInstancesAttributes: data },
  mutateResponse: ({ insulationFamily: { id } = {}, insulationInstances: { created = [], destroyed = [], updated = [] } = {} }) => ({
    insulationInstances: {
      created: created.map(instance => ({
        ...instance,
        insulationFamily: `${id}`,
        insulationFamilyId: `${id}`,
      })),
      destroyed: destroyed.map(instance => ({
        ...instance,
        insulationFamily: `${id}`,
        insulationFamilyId: `${id}`,
      })),
      updated: updated.map(instance => ({
        ...instance,
        insulationFamily: `${id}`,
        insulationFamilyId: `${id}`,
      })),
    }
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});
