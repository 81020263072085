import EntityRecord, { VALIDATORS, FILTERS } from '../../modules/entities/model';

export const ALLOWANCES = {
  MCCS_CODE: /^(.{0,1024})$/,
};

class BoltNutInstance extends EntityRecord({
  sizeProperty: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'sizeId',
    validate: (entity) => VALIDATORS.IS_REQUIRED_ENTITY(entity.sizeProperty),
    validateError: 'A valid size must be present',
    filter: FILTERS.ENTITY,
  },
  schedule: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'scheduleId',
    validate: (entity) => VALIDATORS.IS_REQUIRED_ENTITY(entity.schedule),
    validateError: 'A valid schedule must be present',
    filter: FILTERS.ENTITY,
  },
  mccsCode: {
    value: null,
    handleCopy: (instance) => `${instance.mccsCode} COPY`,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      VALIDATORS.IS_REQUIRED_STRING(entity.mccsCode) &&
      ALLOWANCES.MCCS_CODE.test(entity.mccsCode),
    validateError: 'A string no longer than 1024 characters must be present',
    filter: FILTERS.STRING,
  },
  bulkItemName: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) => VALIDATORS.IS_REQUIRED_STRING(entity.bulkItemName),
    validateError: 'A non-empty string must be present',
    filter: FILTERS.STRING,
  },
  quantity: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) => VALIDATORS.IS_POSITIVE_INTEGER(entity.quantity),
    validateError: 'Must be a non-zero integer',
  },
  boltType: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) => VALIDATORS.IS_OPTIONAL_STRING(entity.boltType),
    validateError: 'Must either be empty or a valid string',
    filter: FILTERS.STRING,
  },
  materialDescription: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      VALIDATORS.IS_OPTIONAL_STRING(entity.materialDescription),
    validateError: 'Must either be empty or a valid string',
    filter: FILTERS.STRING,
  },
  boltNutFamily: {
    value: null,
    isEntity: true,
    handleCopy: true,
  },
  boltNutFamilyId: {
    value: null,
    handleCopy: true,
  },
}) {}

export default BoltNutInstance;
