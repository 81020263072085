import EK from '../keys';

import baseSchema from '../../modules/entities/utils/schema';

import profileSchema from '../Profiles/schema';

const profileTableSchema = baseSchema(EK.PROFILE_TABLES.state, {
  profile: { schema: profileSchema, isId: true},
});

export default profileTableSchema;
