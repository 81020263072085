import { createSelector } from 'reselect';
import { matchPath } from 'react-router-dom';

import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';
import { selectCurrentProfileId } from '../Profiles/selectors';
import { selectRouteLocationPathname } from '../../modules/router/selectors';
import { FrameworkModuleTablePath } from '../../paths';
import { selectTableColumnsById, selectCurrentTableColumns } from '../ProfileTableColumns/selectors';

export const selectNormalizedTables = () => createSelector(
  selectEntities(),
  (entities) => entities.get(EK.PROFILE_TABLES.state)
);
export const selectNormalizedProfileTablesList = () => createSelector(
  selectCurrentProfileId(),
  selectNormalizedTables(),
  (currentProfileId, tables) => tables.filter( table => table.profile == currentProfileId).toList()
);

export const selectCurrentTable = () => createSelector(
  selectRouteLocationPathname(),
  pathname => {
    const match = matchPath(pathname, { path: FrameworkModuleTablePath.url });
    return match && match.params.tableId || null;
  }
);
export const selectCurrentTableData = () => createSelector(
  selectCurrentTable(),
  selectNormalizedTables(),
  (tableId, tables) => tables.find( item => item.id == tableId )
);
export const selectCurrentTableNameFromId = (tableId) => createSelector(
  selectNormalizedTables(),
  (tables) => {
    const table = tables.find(item => item.id == tableId);

    return table && table.tableName || tableId;
  }
);

export const selectSubTableDataFromId = (tableId) => createSelector(
  selectNormalizedTables(),
  (tables) => tables.find(item => item.id == tableId)
);

export const selectParameterOrDimensionColumns = (tableId) => createSelector(
  selectEntities(),
  selectTableColumnsById(tableId),
  (entities, columns) => {
    const params = entities.get(EK.PROFILE_PARAMETERS.state);
    const dims = entities.get(EK.DIMENSIONS.state);
    if(columns.length < 1 || params.size < 1 && dims.size < 1) {
      return [{ header: 'No Columns Found', field: 'nocolumns', id: '', columnId: '', columnType: 'subtable' }];
    }
    return columns.map( (col) => {
      if(col.columnType == 'subtable') {
        return { header: col.profileSubtableColumnName, field: 'isTable', id: col.profileSubtableColumnName, columnId: col.id, columnType: 'subtable', profileSubtableColumnName: col.profileSubtableColumnName };
      } else if (col.columnType == 'parameter') {
        let curparam = params.find(param => param.id == col.profileParameterId);
        return { header: curparam.parameterName, field: curparam.id, valueType: curparam.valueType, columnType: 'parameter', id: curparam.id, columnId: col.id };
      } else {
        let curdim = dims.find(dim => dim.id == col.profileDimensionId);
        return { header: curdim.dimensionName, field: curdim.id, valueType: 'double', columnType: 'dimension', id: curdim.id, columnId: col.id };
      }
    });
  }
);

export const selectCurrentParameterOrDimensionColumns = () => createSelector(
  selectEntities(),
  selectCurrentTableColumns(),
  (entities, columns) => {
    const params = entities.get(EK.PROFILE_PARAMETERS.state);
    const dims = entities.get(EK.DIMENSIONS.state);
    if (columns.length < 1 || params.size < 1 && dims.size < 1) {
      return [{ header: 'No Columns Found', field: 'nocolumns', id: '', columnId: '', columnType: 'subtable' }];
    }
    return columns.map((col) => {
      if (col.columnType == 'subtable') {
        return { header: col.profileSubtableColumnName, field: 'isTable', id: col.profileSubtableColumnName, columnId: col.id, columnType: 'subtable', profileSubtableColumnName: col.profileSubtableColumnName };
      } else if (col.columnType == 'parameter') {
        let curparam = params.find(param => param.id == col.profileParameterId);
        return { header: curparam.parameterName, field: curparam.id, valueType: curparam.valueType, columnType: 'parameter', id: curparam.id, columnId: col.id };
      } else {
        let curdim = dims.find(dim => dim.id == col.profileDimensionId);
        return { header: curdim.dimensionName, field: curdim.id, valueType: 'double', columnType: 'dimension', id: curdim.id, columnId: col.id };
      }
    });
  }
);