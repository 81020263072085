import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../../entities/keys';

import { selectIsShowingArchived } from '../../../modules/query/selectors';

import { selectNormalizedConfigProfilesList } from '../../../entities/ConfigProfiles/selectors';

import { selectSidebarIsFiltering } from '../../Dashboard/selectors';

const configProfileFilterForm = formValueSelector(`filters.${EK.CONFIG_PROFILES.state}`);

export const selectCurrentFilteredConfigProfiles = () => createSelector(
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectNormalizedConfigProfilesList(),
  state => configProfileFilterForm(state, 'name'),
  (isFiltering, isShowingArchived, configProfiles, name) => isFiltering ?
    configProfiles.filter(configProfile => (isShowingArchived || !configProfile.archivedFlag) && configProfile.doesMatchQuery({ name })).toArray() :
    configProfiles.filter(configProfile => !configProfile.archivedFlag).toArray()
);

export const selectCurrentConfigProfileFilterIsAdvanced = () => createSelector(
  state => configProfileFilterForm(state, 'isAdvanced'),
  (isAdvanced) => isAdvanced
);