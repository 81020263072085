import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

import NoteBlockSchema from './schema';

export const selectNormalizedNoteBlocks = () =>
  createSelector(
    selectEntities(),
    (entities) => entities.get('noteBlocks'),
  );
export const selectNormalizedDraftNoteBlocks = () =>
  createSelector(
    selectDrafts(),
    (drafts) => {
      const state = drafts.get('noteBlocks');
      const records = state.get('records');
      return state.get('ids').map((id) => records.get(id));
    },
  );

export const selectNoteBlocks = () =>
  createSelector(
    selectEntities(),
    (entities) =>
      denormalize(
        entities.get('noteBlocks'),
        [NoteBlockSchema],
        entities.delete('noteProfiles'),
      ),
  );
export const selectDraftNoteBlocks = () =>
  createSelector(
    selectEntities(),
    selectNormalizedDraftNoteBlocks(),
    (entities, drafts) =>
      denormalize(drafts, [NoteBlockSchema], entities.delete('noteProfiles')),
  );
