import { toast } from 'react-toastify';

import EK from '../keys';

import fittingClient from './client';

import { REQUEST_TYPES } from '../../modules/api/constants';

import {
  fetchAllFittingFamiliesTypes,
  fetchIndividualFittingFamilyTypes,
  createFittingFamilyTypes,
  copyFittingFamilyTypes,
  editFittingFamilyTypes,
  deleteFittingFamilyTypes,
  archiveFittingFamiliesTypes,
  unarchiveFittingFamiliesTypes,
} from './constants';

export const processFetchAllFittingFamilies = () => fittingClient(REQUEST_TYPES.GET, 'plural', fetchAllFittingFamiliesTypes, '/piping/fitting_families?all=true');

export const processFetchFittingFamiliesForSpec = () => fittingClient(REQUEST_TYPES.GET, 'plural', fetchAllFittingFamiliesTypes, '/piping/fitting_families?all=true&with_full_detail=true',);

export const processFetchIndividualFittingFamily = fittingId => fittingClient(REQUEST_TYPES.GET, 'single', fetchIndividualFittingFamilyTypes, `/piping/fitting_families/${fittingId}?with_full_detail=true`, {
  mutateResponse: ({ fittingFamily: { id, fittingCodeId, fittingInstances = [], ...other } = {} }) => ({
    fittingFamily: {
      ...other,
      id,
      fittingCodeId,
      fittingInstances: fittingInstances.map(instance => ({
        ...instance,
        fittingCodeId,
        fittingFamily: `${id}`,
        fittingFamilyId: `${id}`,
      })),
    },
  }),
});

export const processCreateFittingFamily = data => fittingClient(REQUEST_TYPES.POST, 'plural', createFittingFamilyTypes, '/piping/fitting_families', {
  data: {
    fittingFamilies: [data],
  },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Fitting successfully created',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to create fitting',
  },
});

export const processCopyFittingFamily = (data) =>
  fittingClient(
    REQUEST_TYPES.POST,
    'plural',
    copyFittingFamilyTypes,
    '/piping/fitting_families/copy',
    {
      data: {
        fittingFamilies: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Fitting successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy fitting',
      },
    }
  );

export const processEditFittingFamily = (fittingId, data) => fittingClient(REQUEST_TYPES.PATCH, 'single', editFittingFamilyTypes, `/piping/fitting_families/${fittingId}`, {
  data,
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});

export const processDeleteFittingFamily = fittingId => fittingClient(REQUEST_TYPES.DELETE, 'single', deleteFittingFamilyTypes, `/piping/fitting_families/${fittingId}?with_full_detail=true`, {
  deleteKey: EK.FITTING_FAMILIES.state,
  dependentDeletionKeys: [EK.FITTING_INSTANCES.state],
  mutateResponse: ({ fittingFamily: { id, fittingCodeId, ...other } = {}, fittingInstances = [] }) => ({
    fittingFamily: {
      ...other,
      id,
      fittingCodeId,
      fittingInstances: fittingInstances.map(instance => ({
        ...instance,
        fittingCodeId,
        fittingFamily: `${id}`,
        fittingFamilyId: `${id}`,
      })),
    },
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Fitting deleted',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to delete fitting',
  },
});

export const processArchiveFittingFamilies = fittingIds => fittingClient(REQUEST_TYPES.ARCHIVE, 'plural', archiveFittingFamiliesTypes, '/piping/fitting_families/archive', {
  data: { ids: fittingIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Fitting archived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to archive fitting',
  },
});

export const processUnarchiveFittingFamilies = fittingIds => fittingClient(REQUEST_TYPES.UNARCHIVE, 'plural', unarchiveFittingFamiliesTypes, '/piping/fitting_families/unarchive', {
  data: { ids: fittingIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Fitting unarchived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to unarchive fitting',
  },
});




// import compact from 'lodash/compact';
//
// import { put, select } from 'redux-saga/effects';
//
// import FittingFamilySchema from './schema';
// import ENTITIES from '../../modules/entities/constants';
// import * as ActionTypes from '../../utils/actions';
// import { selectFittingFamilies } from './selectors';
//
// import { edit as editDrafts } from '../../modules/_drafts/actions';
//
// import fittingInstancesKeys from '../../modules/FittingInstances/keys';
// import { selectNormalizedFittingInstances, selectDraftFittingInstancesToUpdate, selectDraftFittingInstances } from '../../modules/FittingInstances/selectors';
//
// import fittingEndPortsKeys from '../../modules/FittingEndPorts/keys';
// import { selectNormalizedFittingEndPorts } from '../../modules/FittingEndPorts/selectors';
//
// export const fetchAll = ActionTypes.fetchListAction(ENTITIES.FETCH_ALL, FittingFamilySchema, {
//   urlFn: ({ withFullDetails }) => `/piping/fitting_families?all=true${ withFullDetails ? '&with_full_detail=true' : '' }`,
//   errorMessage: 'We had some trouble fetching your fittings. If this keeps happening, contact support and we\'ll help you take care of it.',
// });
//
// export const fetch = ActionTypes.fetchIndividualAction(ENTITIES.FETCH, FittingFamilySchema, {
//   urlFn: ({ fittingFamilyId }) => `/piping/fitting_families/${fittingFamilyId}?with_full_detail=true`,
//   errorMessage: 'We had some trouble fetching your fitting. If this keeps happening, contact support and we\'ll help you take care of it.',
// });
//
// export const fetchAndLoadForEdit = ActionTypes.fetchIndividualAction(ENTITIES.FETCH, FittingFamilySchema, {
//   urlFn: ({ fittingFamilyId }) => `/piping/fitting_families/${fittingFamilyId}?with_full_detail=true`,
//   errorMessage: 'We had some trouble fetching your fitting. If this keeps happening, contact support and we\'ll help you take care of it.',
//   successFn: function* fittingFamilyLoadForEditSuccessFn(transformedData, action) {
//     const fittingInstances = yield select(selectNormalizedFittingInstances());
//     const fittingEndPorts = yield select(selectNormalizedFittingEndPorts());
//
//     yield put(editDrafts.load([{ entityKey: fittingInstancesKeys.state, entities: fittingInstances.filter((fittingInstance) => `${action.fittingFamilyId}` === fittingInstance.fittingFamily) }]));
//     yield put(editDrafts.load([{ entityKey: fittingEndPortsKeys.state, entities: fittingEndPorts }]));
//   }
// });
//
// export const create = ActionTypes.createAction(ENTITIES.CREATE, FittingFamilySchema, {
//   url: '/piping/fitting_families',
//   errorNotification: {
//     title: 'Uh oh',
//     message: 'We had trouble creating your fittings. Please try again in a moment or contact our support team if the issue persists.',
//   },
//   successNotification: {
//     title: 'Nice',
//     message: 'Your fittings have been successfully created. You can start adding fitting instances.',
//   },
// });
//
// // TODO: turn this into an updateCollection endpoint? or at least use FittingInstanceSchema
// export const update = ActionTypes.updateAction(ENTITIES.UPDATE, FittingFamilySchema, {
//   urlFn: ({ fittingFamilyId }) => `/piping/fitting_families/${fittingFamilyId}`,
//   dataFn: function* updateFittingFamilyDataFn(action) {
//     // const fittingInstancesToUpdate = yield select(selectDraftFittingInstancesToUpdate());
//     const fittingInstances = yield select(selectDraftFittingInstances());
//
//     const fittingInstancesToUpdate = compact(fittingInstances.map(instance => {
//       if (instance.isSelected) {
//         const endPortsHaveValidEdits = instance.fittingEndPorts.some(endPort => endPort.isEdited && endPort.isValid)
//
//         if ((instance.isEdited && instance.isValid) || (instance.isValid && endPortsHaveValidEdits)) {
//           if (instance.isNew) {
//             return {
//               fittingEndPortsAttributes: instance.fittingEndPorts.map(endPort => endPort.serialize()).toArray(),
//               ...instance.serialize(),
//             };
//           } else {
//             return {
//               id: instance.id,
//               fittingEndPortsAttributes: instance.fittingEndPorts.map(endPort => endPort.serialize()).toArray(),
//               ...instance.serialize(),
//             };
//           }
//         }
//       } else {
//         if (!instance.isNew) {
//           return { id: instance.id, _destroy: '1' };
//         }
//       }
//
//       return null;
//     }).toArray());
//
//     return { fittingInstancesAttributes: fittingInstancesToUpdate };
//   },
//   updateKey: 'fittingInstances',
//   errorNotification: {
//     title: 'Uh oh',
//     message: 'We had trouble updating your fittings. Please try again in a moment or contact our support team if the issue persists.',
//   },
//   successNotification: {
//     title: 'Success',
//     message: 'Your fittings have been successfully updated.',
//   },
//   successFn: function* fittingFamilyLoadForUpdateSuccessFn(transformedData, action) {
//     const fittingInstances = yield select(selectNormalizedFittingInstances());
//     const fittingEndPorts = yield select(selectNormalizedFittingEndPorts());
//
//     yield put(editDrafts.load([{ entityKey: fittingInstancesKeys.state, entities: fittingInstances.filter((fittingInstance) => `${action.fittingFamilyId}` === fittingInstance.fittingFamily) }]));
//     yield put(editDrafts.load([{ entityKey: fittingEndPortsKeys.state, entities: fittingEndPorts }]));
//   }
// });
//
// export const archive = ActionTypes.updateAction(ENTITIES.ARCHIVE, FittingFamilySchema, {
//   urlFn: ({ fittingFamilyId }) => `/piping/fitting_families/${action.fittingFamilyId}`,
//   errorNotification: {
//     title: 'Uh oh',
//     message: 'We had trouble archiving your fittings. Please try again in a moment or contact our support team if the issue persists.',
//   },
//   successNotification: {
//     title: 'Success',
//     message: 'The selected fittings have been successfully archived.',
//   },
// });
//
// export const unarchiveCollection = ActionTypes.updateCollectionAction(ENTITIES.UNARCHIVE_COLLECTION, FittingFamilySchema, {
//   url: '/piping/fitting_families/unarchive',
//   errorNotification: {
//     title: 'Uh oh',
//     message: 'We had trouble unarchiving your fittings. Please try again in a moment or contact our support team if the issue persists.',
//   },
//   successNotification: {
//     title: 'Success',
//     message: 'The selected fittings have been successfully unarchived.',
//   },
// });
