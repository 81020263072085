import { compose } from 'redux';
import { List } from 'immutable';

import EntityRecord, { VALIDATORS, FILTERS } from '../../modules/entities/model';
import hasMeasurements from '../../modules/entities/decorators/hasMeasurements';

export const ALLOWANCES = {
  CUT_NUMBER: /^[1-9]+[0-9]*$/,
};

class MiterInstance extends EntityRecord({
  sizeProperty: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'sizeId',
    validate: entity => VALIDATORS.IS_REQUIRED_ENTITY(entity.sizeProperty),
    validateError: 'A valid size must be present',
    filter: FILTERS.ENTITY,
  },
  radius: {
    value: List(),
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    isUnit: true,
    unitType: 'unitLength',
    validate: entity => VALIDATORS.IS_REQUIRED_VALID_UNIT(entity.radius),
    validateError: 'A numeric radius must be present',
  },
  minAngle: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: entity => VALIDATORS.IS_NUMERIC(entity.minAngle),
    validateError: 'A numeric min angle must be present',
  },
  maxAngle: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: entity => VALIDATORS.IS_NUMERIC(entity.maxAngle),
    validateError: 'A numeric max angle must be present',
  },
  cutNumber: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: entity => VALIDATORS.IS_POSITIVE_INTEGER(entity.cutNumber),
    validateError: 'A cut number must be present',
  },
  miterFamily: {
    value: null,
    isEntity: true,
    handleCopy: true,
  },
  miterFamilyId: {
    value: null,
    handleCopy: true,
  }
}) {}

const enhance = compose(
  hasMeasurements
);

export default enhance(MiterInstance);