import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = 'APPEARANCE_STATES';

export const fetchAllAppearanceStateTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createAppearanceStateTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const copyAppearanceStateTypes = generateAPHTypes(
  resource,
  'COPY'
);

export const editAppearanceStateTypes = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteAppearanceStateTypes = generateAPHTypes(
  resource,
  'DELETE'
);