import { createSelector } from 'reselect';

import selectEntities from '../../modules/entities/selectors';
import { newEndTypeRows } from './constants';
import { selectNormalizedEndTypeCompatibilities } from '../EndTypeCompatibilities/selectors';
import { selectNormalizedSchedules } from '../Schedules/selectors';

export const selectNormalizedEndTypes = () => createSelector(
  selectEntities(),
  entities => entities.get('endTypes')
);
export const selectEndTypesList = () => createSelector(
  selectNormalizedEndTypes(),
  (endTypes) => endTypes.toList()
);

export const selectAllEndTypesOptions = () => createSelector(
  selectEndTypesList(),
  (endTypes) => endTypes.map(endType => endType.toOption())
);

export const selectAllEndTypesArray = () => createSelector(
  selectEndTypesList(),
  (endTypes) => endTypes.toArray().map(endType => ({
    name: endType.name,
    code: endType.code,
    id: endType.id,
    isEdited: false,
    isDeleted: false,
    isCreated: false,
    canSafelyDelete: endType.canSafelyDelete,
  })).concat(newEndTypeRows)
);

export const isLoadingEndTypesData = () => createSelector(
  selectNormalizedEndTypes(),
  selectNormalizedEndTypeCompatibilities(),
  selectNormalizedSchedules(),
  (endTypes, compats, schedules) => !endTypes && !compats && !schedules
);