import EK from '../keys';

import baseSchema from '../../modules/entities/utils/schema';

import UnitSystemSchema from '../UnitSystems/schema';

const pipingCatalogSchema = baseSchema(EK.PIPING_CATALOGS.state, {
  unitSystem: { schema: UnitSystemSchema, isId: true },
});

export default pipingCatalogSchema;
