import { toast } from 'react-toastify';

import EK from '../keys';

import api from '../../modules/api/actions';
import { REQUEST_TYPES } from '../../modules/api/constants';

import {
  fetchAllExplodeStateTypes,
  createExplodeStateTypes,
  copyExplodeStateTypes,
  editExplodeStateTypes,
  deleteExplodeStateTypes
} from './constants';
import schema from './schema';

const client = api(schema, EK.EXPLODE_STATES);

export const processFetchAllExplodeStates = () =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllExplodeStateTypes,
    '/standardize/explode_states'
  );

export const processCreateExplodeState = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    createExplodeStateTypes,
    '/standardize/explode_states',
    {
      data: {
        explodeStates: [{
          name: data.name,
          description: data.description
        }],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Explode State successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create Explode State',
      },
    }
  );

export const processEditExplodeState = (ExplodeStateId, data) =>
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editExplodeStateTypes,
    `/standardize/explode_states/${ExplodeStateId}`,
    {
      data,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

export const processDeleteExplodeState = (ExplodeStateId) =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteExplodeStateTypes,
    `/standardize/explode_states/${ExplodeStateId}?with_full_detail=true`,
    {
      deleteKey: EK.EXPLODE_STATES.state,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Explode State deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete Explode State',
      },
    }
  );


//not sure this is necessary, not going to test it or add it until James approves
export const processCopyExplodeState = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copyExplodeStateTypes,
    '/standardize/explode_states/copy',
    {
      data: {
        explodeStates: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Explode State successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy Explode State',
      },
    }
  );