import baseSchema from '../../modules/entities/utils/schema';

import EK from '../keys';

import NoteProfileSchema from '../NoteProfiles/schema';
import ParameterProfileSchema from '../ParameterProfiles/schema';
import LayerSchema from '../Layers/schema';
import SectionSchema from '../Sections/schema';
import SimplifiedRepSchema from '../SimplifiedReps/schema';
import OrientationSchema from '../Orientations/schema';

// prettier-ignore
const modelTemplateSchema = baseSchema(EK.MODEL_TEMPLATES.state, {
  noteProfile: { schema: NoteProfileSchema, isId: true },
  modelParameterProfile: { schema: ParameterProfileSchema, isId: true },
  componentParameterProfile: { schema: ParameterProfileSchema, isId: true },
  orientations: { schema: [OrientationSchema], isId: true, idField: 'orientationIds', isList: true },
  layers: { schema: [LayerSchema], isId: true, idField: 'layerIds', isList: true },
  sections: { schema: [SectionSchema], isId: true, idField: 'sectionIds', isList: true },
  simplifiedReps: { schema: [SimplifiedRepSchema], isId: true, idField: 'simplifiedRepIds', isList: true },
});

export default modelTemplateSchema;
