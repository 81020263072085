import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';
import SpecSchema from './schema';
import { selectCurrentFilteredPipes } from '../PipeFamilies/selectors';
import { selectisPrioritizedFamilySortingEnabled } from '../../routes/SpecsEditSwitch/selectors';
import { selectCurrentFilteredFittings } from '../FittingFamilies/selectors';

export const selectNormalizedSpecs = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.SPECS.state)
);

export const selectNormalizedUnarchivedSpecs = () => createSelector(
  selectNormalizedSpecs(),
  specs => {
    return specs.toList().filter(item => !item.archivedFlag);
  }
);

export const selectSpecsCounts = () => createSelector(
  selectNormalizedSpecs(),
  specs => specs.reduce(({ unarchived, total }, spec) => ({
    unarchived: !spec.archivedFlag ? unarchived + 1 : unarchived,
    total: total + 1,
  }), {
    unarchived: 0,
    total: 0,
  })
);

export const selectSpecs = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.SPECS.state), [SpecSchema], entities.delete(EK.SPEC_PIPE_INSTANCES.state).delete(EK.SPEC_FITTING_INSTANCES.state))
);
export const selectSpecsList = () => createSelector(
  selectSpecs(),
  specs => specs.toList()
);

export const selectCurrentSpec = (specId) => createSelector(
  selectEntities(),
  (entities) => entities.hasIn([EK.SPECS.state, specId]) && denormalize(entities.getIn([EK.SPECS.state, specId]), SpecSchema, entities)
);

export function removeDuplicateFromObjectIdArray(arr) {
  // borrowed this from https://www.geeksforgeeks.org/how-to-get-all-unique-values-remove-duplicates-in-a-javascript-array/, it's pretty slick.
  let outputArray = arr.filter(function (v, i, self) {
    return v?.id && i == self.findIndex((item) => item?.id == v.id);
  });

  return outputArray;
}

export const selectOrderedFilteredUnarchivedPipeFamilies = (specId) =>
  createSelector(
    selectCurrentSpec(specId),
    selectCurrentFilteredPipes(),
    selectisPrioritizedFamilySortingEnabled(),
    (
      spec,
      pipes,
      isPrioritizedFamilySortingEnabled,
    ) => {
      let ordered = spec && spec.prioritizedPipeFamilies?.map( (item)=> {
        if (item && item.id) {
          return {
            id: item.id,
            isSelectedForSpec: true,
            isPrioritized: true,
            name: item.name,
            material: item.material,
            pipeInstances: item.pipeInstances,
            selectedPipeInstancesForSpec: spec.specPipeInstances.filter(inst => inst.pipeFamilyId?.id == item.id).size,
            originalData: {
              id: item.id,
              isSelectedForSpec: true,
              isPrioritized: true,
              name: item.name,
              material: item.material,
              pipeInstances: item.pipeInstances,
              selectedPipeInstancesForSpec: spec.specPipeInstances.filter(inst => inst.pipeFamilyId?.id == item.id).size,
            },
            isEdited: false,
            isDeleted: false,
          };
        }
      }
      ) || []; // list of pipe families from the ordered list
      const pipeArray = pipes && pipes.toList().toArray().map((item) => {
        if (item && item.id) {
          return {
            id: item.id,
            isSelectedForSpec: false,
            isPrioritized: false,
            name: item.name,
            material: item.material,
            pipeInstances: item.pipeInstances,
            selectedPipeInstancesForSpec: 0,
            originalData: {
              id: item.id,
              isSelectedForSpec: false,
              isPrioritized: false,
              name: item.name,
              material: item.material,
              pipeInstances: item.pipeInstances,
              selectedPipeInstancesForSpec: 0,
            },
            isEdited: false,
            isDeleted: false,
          };
        }
      }); // all pipe families
      const specPipeArray = spec.specPipeInstances?.size > 0 && removeDuplicateFromObjectIdArray(spec.specPipeInstances.toArray().map(item => {
        if (item && item.pipeFamilyId?.id) {
          return {
            id: item.pipeFamilyId.id,
            isSelectedForSpec: true,
            isPrioritized: false,
            name: item.pipeFamilyId.name,
            material: item.pipeFamilyId.material,
            pipeInstances: item.pipeFamilyId.pipeInstances,
            selectedPipeInstancesForSpec: spec.specPipeInstances.filter(inst => inst.pipeFamilyId?.id == item.pipeFamilyId.id).size,
            originalData: {
              id: item.id,
              isSelectedForSpec: true,
              isPrioritized: false,
              name: item.name,
              material: item.material,
              pipeInstances: item.pipeInstances,
              selectedPipeInstancesForSpec: spec.specPipeInstances.filter(inst => inst.pipeFamilyId?.id == item.pipeFamilyId.id).size,
            },
            isEdited: false,
            isDeleted: false,
          };
        }
      })) || []; // list of all pipe family ids not in the ordered list
      ordered = removeDuplicateFromObjectIdArray(ordered.concat(specPipeArray));
      return isPrioritizedFamilySortingEnabled ?
        ordered :
        pipeArray && removeDuplicateFromObjectIdArray(ordered.concat(pipeArray)) || ordered;
    }
  );

export const selectOrderedFilteredUnarchivedFittingFamilies = (specId) =>
  createSelector(
    selectCurrentSpec(specId),
    selectCurrentFilteredFittings(),
    selectisPrioritizedFamilySortingEnabled(),
    (
      spec,
      fittings,
      isPrioritizedFamilySortingEnabled,
    ) => {
      let ordered = spec && spec.prioritizedFittingFamilies?.map((item) => {
        if (item && item.id) {
          return {
            id: item.id,
            isSelectedForSpec: true,
            isPrioritized: true,
            name: item.name,
            fittingCode: item.fittingCode,
            fittingCategory: item.fittingCategory,
            fittingInstances: item.fittingInstances,
            selectedFittingInstancesForSpec: spec.specFittingInstances.filter(inst => inst.fittingFamilyId?.id == item.id).size,
            originalData: {
              id: item.id,
              isSelectedForSpec: true,
              isPrioritized: true,
              name: item.name,
              material: item.material,
              fittingInstances: item.fittingInstances,
              selectedFittingInstancesForSpec: spec.specFittingInstances.filter(inst => inst.fittingFamilyId?.id == item.id).size,
            },
            isEdited: false,
            isDeleted: false,
          };
        }
      }
      ) || []; // list of fitting families from the ordered list
      let fittingArray = fittings && fittings.toList().toArray().map((item) => {
        if (item && item.id) {
          return {
            id: item.id,
            isSelectedForSpec: false,
            isPrioritized: false,
            name: item.name,
            fittingCode: item.fittingCode,
            fittingCategory: item.fittingCategory,
            fittingInstances: item.fittingInstances,
            selectedFittingInstancesForSpec: 0,
            originalData: {
              id: item.id,
              isSelectedForSpec: false,
              isPrioritized: false,
              name: item.name,
              material: item.material,
              fittingInstances: item.fittingInstances,
              selectedFittingInstancesForSpec: 0,
            },
            isEdited: false,
            isDeleted: false,
          };
        }
      }); // all fitting families
      let specFittingArray = spec.specFittingInstances?.size > 0 && removeDuplicateFromObjectIdArray(spec.specFittingInstances.toArray().map(item => {
        if (item && item.fittingFamilyId?.id) {
          return {
            id: item.fittingFamilyId.id,
            isSelectedForSpec: true,
            isPrioritized: false,
            name: item.fittingFamilyId.name,
            fittingCode: item.fittingFamilyId.fittingCode,
            fittingCategory: item.fittingFamilyId.fittingCategory,
            fittingInstances: item.fittingFamilyId.fittingInstances,
            selectedFittingInstancesForSpec: spec.specFittingInstances.filter(inst => inst.fittingFamilyId?.id == item.fittingFamilyId.id).size,
            originalData: {
              id: item.id,
              isSelectedForSpec: true,
              isPrioritized: false,
              name: item.name,
              fittingCode: item.fittingFamilyId.fittingCode,
              fittingCategory: item.fittingFamilyId.fittingCategory,
              fittingInstances: item.fittingFamilyId.fittingInstances,
              selectedFittingInstancesForSpec: spec.specFittingInstances.filter(inst => inst.fittingFamilyId?.id == item.fittingFamilyId.id).size,
            },
            isEdited: false,
            isDeleted: false,
          };
        }
      })) || []; // list of all fitting family ids not in the ordered list
      ordered = removeDuplicateFromObjectIdArray(ordered.concat(specFittingArray));
      return isPrioritizedFamilySortingEnabled ?
        ordered :
        fittingArray && removeDuplicateFromObjectIdArray(ordered.concat(fittingArray)) || ordered;
    }
  );
