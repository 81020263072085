import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import RowEndEntityTools from '../../../components/common/EntityTools/RowEndTools';
import { push } from 'connected-react-router';
import { createSelector } from 'reselect';

import { selectIsShowingArchived } from '../../../modules/query/selectors';

import { selectNormalizedParameterProfilesList } from '../../../entities/ParameterProfiles/selectors';

import {
  processFetchAllParameterProfiles,
  processCreateParameterProfile,
  processCopyParameterProfile,
  processEditParameterProfile,
  processDeleteParameterProfile,
  processArchiveParameterProfiles,
  processUnarchiveParameterProfiles,
} from '../../../entities/ParameterProfiles/actions';

import {
  selectSidebarIsFiltering,
  selectStandardizeModulePermissionsAndState,
} from '../../Dashboard/selectors';

import {
  dialogFooter,
  deleteDialogFooter,
  nameBody,
  areFieldsValid,
  doRequiredFieldsExist
} from './components';

import Header from './Header';
import Options from '../../templates/Structures/Options';
import Flex from '../../../components/common/Flex/index';
import Text from '../../../components/common/Text';

import { ParameterProfilesEditPath } from '../../../paths';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectNormalizedParameterProfilesList(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: !isLoading && data || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

const BLANK_PROFILE = {
  name: '',
};

const VALIDATION_FIELDS = {
  name: true,
};

export default function (props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false); // controls if the create/edit/copy dialog is open
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false); // used to determine header text and select 'save' action
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false); // used to determine header text and select 'save' action
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); // controls if the delete Dialog is open
  const [validation, setValidation] = useState(VALIDATION_FIELDS);

  const [currentEditingRow, setCurrentEditingRow] = useState(BLANK_PROFILE);

  const getDialogHeader = () => {
    if (isCreateDialogOpen) {
      return 'Create Parameter Profile';
    } else if (isCopyDialogOpen) {
      return `Create Copy of '${currentEditingRow.name}'`;
    } else {
      return `Edit '${currentEditingRow.name}'`;
    }
  };

  const openCreateDialog = () => {
    setCurrentEditingRow(BLANK_PROFILE);
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  const cancelDialogs = () => {
    // used to reset the dialogs
    setCurrentEditingRow(BLANK_PROFILE);
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
    setIsDeleteDialogOpen(false);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setCurrentEditingRow(BLANK_PROFILE);
  };

  const saveAction = () => {

    setValidation(areFieldsValid(currentEditingRow));
    if (!doRequiredFieldsExist(currentEditingRow)) {
      return;
    }

    const editedRow = {
      id: currentEditingRow.id,
      name: currentEditingRow.name,
    };

    if (isCreateDialogOpen) {
      dispatch(processCreateParameterProfile(editedRow));
    } else if (isCopyDialogOpen) {
      dispatch(processCopyParameterProfile(editedRow));
    } else {
      dispatch(processEditParameterProfile(editedRow.id, editedRow));
    }
    cancelDialogs();
  };

  const editRowAction = (rowdata) => {
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsDialogOpen(true);
  };

  const copyRowAction = (rowdata) => {
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsCopyDialogOpen(true);
    setIsDialogOpen(true);
  };

  const archiveAction = (rowdata) => {
    dispatch(processArchiveParameterProfiles([rowdata.id]));
  };

  const unarchiveAction = (rowdata) => {
    dispatch(processUnarchiveParameterProfiles([rowdata.id]));
  };

  const deleteAction = () => {
    // this action is PERMANENT, never call directly off of a button, always use the modal
    dispatch(processDeleteParameterProfile(currentEditingRow.id));
    setCurrentEditingRow(BLANK_PROFILE);
    setIsDeleteDialogOpen(false);
  };

  const openDeleteDialog = () => {
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  const handleEditedRowChange = (newValue, field) => {
    // called on every edit
    let _editedRow = { ...currentEditingRow };
    _editedRow[`${field}`] = newValue;
    setCurrentEditingRow(_editedRow);
  };

  const manageParameterProfile = (parameterProfileId) => {
    dispatch(push(ParameterProfilesEditPath.generate({ id: parameterProfileId })));
  };

  useEffect(() => {
    dispatch(processFetchAllParameterProfiles());
  }, []);

  return (
    <>
      <Flex flexDirection='row' mb={4}>
        <Header/>
        <Options
          isLoading={reduxProps.isLoading}
          createEntity={openCreateDialog}
          shouldHaveLicense={false}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex>
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable value={reduxProps.data} tableStyle={{ minWidth: '50rem' }} size='normal' scrollable scrollHeight='flex' >
          <Column field='name' header='Name' style={{width: '20%'}} body={(rowData) => nameBody(rowData, manageParameterProfile)}></Column>
          <Column header=''
            style={{textAlign: 'right', width: '20%'}}
            body={(rowData) =>
              <RowEndEntityTools
                rowdata={rowData}
                editAction={editRowAction}
                copyAction={copyRowAction}
                listAction={(data) => manageParameterProfile(data.id)}
                archiveAction={archiveAction}
                unarchiveAction={unarchiveAction}
              />}
          ></Column>
        </DataTable>
      </Flex>
      <Dialog visible={reduxProps.editable && isDialogOpen} style={{ width: '75%' }} header={()=>getDialogHeader()} footer={() => dialogFooter(isCreateDialogOpen, isCopyDialogOpen, openDeleteDialog, saveAction, cancelDialogs)} modal className='p-fluid' closable={false} >
        <div>
          <label>Name</label>
          <InputText value={currentEditingRow.name} onChange={(e) => handleEditedRowChange(e.target.value, 'name')} className={!validation.name && 'p-invalid'} />
        </div>
      </Dialog>
      <Dialog visible={reduxProps.editable && isDeleteDialogOpen} style={{ width: '32rem' }} header={`Delete Parameter Profile '${currentEditingRow.name}'`} footer={() => deleteDialogFooter(closeDeleteDialog, deleteAction)} closable={false} >
        <div>
          <Text>Are you sure you want to delete {currentEditingRow.name}?</Text>
          <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
          <Text><strong>Only delete this if you are certain that it needs to be removed from everywhere</strong></Text>
        </div>
      </Dialog>
    </>
  );
}