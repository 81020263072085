import baseSchema from '../../modules/entities/utils/schema';

import keys from './keys';
import bendMachineInstanceKeys from '../BendMachineInstances/keys';

import PipeFamilySchema from '../PipeFamilies/schema';
import SizeSchema from '../Sizes/schema';

const bendMachineFamilySchema = baseSchema(keys.state, {
  pipeFamily: { schema: PipeFamilySchema, isId: true },
  bendMachineInstances: { isId: true, idField: 'bendMachineInstanceIds', isList: true },
});

export const bendMachineInstanceSchema = baseSchema(bendMachineInstanceKeys.state, {
  sizeProperty: { schema: SizeSchema, isId: true, originalField: 'size' },
  startClampLength: { isComplexUnit: true },
  midClampLength: { isComplexUnit: true },
  endClampLength: { isComplexUnit: true },
  bendMachineFamily: { schema: bendMachineFamilySchema, isId: true },
});

bendMachineFamilySchema.define({ bendMachineInstances: [ bendMachineInstanceSchema ] });

export default bendMachineFamilySchema;
