import { toast } from 'react-toastify';

import endTypeClient from './client';

import { REQUEST_TYPES } from '../../modules/api/constants';

import { fetchAllEndTypesTypes, saveEndTypesTypes } from './constants';

export const processFetchAllEndTypes = () => endTypeClient(REQUEST_TYPES.GET, 'plural', fetchAllEndTypesTypes, '/piping/end_types');

export const processSaveEndTypes = (data) => endTypeClient(REQUEST_TYPES.PATCH, 'plural', saveEndTypesTypes, '/piping/end_types/collection?diff_result=true', {
  data: { endTypesAttributes: data },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});
