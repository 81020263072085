import { take, put, fork, select } from 'redux-saga/effects';

import { processFetchAllLayers } from '../../entities/Layers/actions';
import { processFetchAllNoteProfiles } from '../../entities/NoteProfiles/actions';
import { processFetchAllNotes } from '../../entities/Notes/actions';
import { processFetchAllOrientations } from '../../entities/Orientations/actions';
import { processFetchAllParameterProfiles } from '../../entities/ParameterProfiles/actions';
import { processFetchAllParameters } from '../../entities/Parameters/actions';
import { processFetchAllSections } from '../../entities/Sections/actions';
import { processFetchAllGlobalCadSearchRules } from '../../entities/GlobalCadSearchRules/actions';
import { processFetchAllSimplifiedReps } from '../../entities/SimplifiedReps/actions';
import { processFetchAllModelTemplates } from '../../entities/ModelTemplates/actions';
import { processFetchAllExplodeStates } from '../../entities/ExplodeStates/actions';
import { processFetchAllCombinedStates } from '../../entities/CombinedStates/actions';
import { processFetchAllLayerStates } from '../../entities/LayerStates/actions';
import { processFetchAllAppearanceStates } from '../../entities/AppearanceStates/actions';
import { processFetchAllStyleStates } from '../../entities/StyleStates/actions';

import { selectIsFetching } from '../../modules/utility/selectors';

import { signout } from '../../modules/auth/sagas';

import { isInitialLoadComplete } from '../Dashboard/actions';

export function* watchLoadStandardizeModuleSaga() {
  try {
    yield put(processFetchAllLayers());
    yield put(processFetchAllNoteProfiles());
    yield put(processFetchAllNotes());
    yield put(processFetchAllOrientations());
    yield put(processFetchAllParameterProfiles());
    yield put(processFetchAllParameters());
    yield put(processFetchAllSections());
    yield put(processFetchAllGlobalCadSearchRules());
    yield put(processFetchAllSimplifiedReps());
    yield put(processFetchAllModelTemplates());
    yield put(processFetchAllExplodeStates());
    yield put(processFetchAllCombinedStates());
    yield put(processFetchAllLayerStates());
    yield put(processFetchAllAppearanceStates());
    yield put(processFetchAllStyleStates());

    let isFetchingInitialEntities = yield select(selectIsFetching());
    while (isFetchingInitialEntities > 0) {
      yield take();
      isFetchingInitialEntities = yield select(selectIsFetching());
    }

    yield put(isInitialLoadComplete());
  } catch (error) {
    yield put(signout());
  }
}

// final output saga
export default function* main() {
  yield fork(watchLoadStandardizeModuleSaga);
}
