import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = 'PIPE_FAMILIES';

export const fetchAllPipeFamiliesTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const fetchIndividualPipeFamilyTypes = generateAPHTypes(resource, 'FETCH');

export const createPipeFamilyTypes = generateAPHTypes(resource, 'CREATE');

export const copyPipeFamilyTypes = generateAPHTypes(
  resource,
  'COPY'
);

export const editPipeFamilyTypes = generateAPHTypes(resource, 'EDIT');

export const deletePipeFamilyTypes = generateAPHTypes(resource, 'DELETE');

export const archivePipeFamiliesTypes = generateAPHTypes(resource, 'ARCHIVE');

export const unarchivePipeFamiliesTypes = generateAPHTypes(resource, 'UNARCHIVE');