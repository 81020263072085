import { List } from 'immutable';

import EntityRecord, { FILTERS } from '../../modules/entities/model';

export const ALLOWANCES = {
  NAME: /^([a-zA-Z0-9_-]{0,31})$/,
};

// prettier-ignore
export default class ProfileLibrary extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  defaultValue: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
  }, // src/entities/ConfigProfileValues uses validators in the same way this probably needs to.
  valueType: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
  },
  description: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  folderName: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  unitSystem: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'unitSystemId',
  },
}) {}
