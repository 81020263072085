import React, { useState, useEffect } from 'react';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// custom hooks
import { useOnCellEditComplete } from '../../../hooks/useOnCellEditComplete';

//Grid
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { rowStyles } from '../../../components/primeGrid/RowStyles';
import PortColumnHeader from '../../../components/grid/PortColumnHeader';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// cell editors/bodies
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';
import { Dropdown } from 'primereact/dropdown';
import Text from '../../../components/common/Text';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import { InputText } from 'primereact/inputtext';
import { ALLOWANCES } from '../../../entities/FittingInstances/model';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

// Header imports
import Options from '../../templates/Structures/Options';
import Header from '../../templates/Structures/Header';
import { pluralize } from '../../../components/common/Header';
import Skeleton from 'react-loading-skeleton';
import Flex from '../../../components/common/Flex';
import { PipingModuleFittingsEditPath } from '../../../paths';

//actions
import {
  processFetchIndividualFittingFamily,
  processEditFittingFamily,
  processArchiveFittingFamilies,
  processUnarchiveFittingFamilies,
} from '../../../entities/FittingFamilies/actions';
import { processFetchAllSizes } from '../../../entities/Sizes/actions';
import { processSaveFittingInstances } from '../../../entities/FittingInstances/actions';
import { processFetchAllFittingCategories } from '../../../entities/FittingCategories/actions';
import { processFetchAllMaterials } from '../../../entities/Materials/actions';

// Fitting Family Dialog
import { doRequiredFieldsExist, areFieldsValid, convertToFittingFamilyType } from '../Fittings/components';
import { VALIDATION_FIELDS } from '../../../entities/FittingFamilies/model';
import FittingFamilyDialog from '../../../containers/dialog/templates/FittingFamilyDialog';

// misc.
import { getUnitLength } from '../../../entities/UnitSystems/model';
import { convertToFittingInstanceType, saveCurrentIsulationInstanceChanges } from './actions';
import colors from '../../../assets/themes/base/colors';

// Selectors
import { selectPipingModulePermissionsAndState } from '../../Dashboard/selectors';
import { selectNormalizedSizes } from '../../../entities/Sizes/selectors';
import { selectNormalizedEndTypes } from '../../../entities/EndTypes/selectors';
import { selectSidebarIsFiltering } from '../../Dashboard/selectors';
import { selectCurrentFittingFamily } from '../../../entities/FittingFamilies/selectors';
import { selectCurrentFilteredFittingInstances, selectFittingInstanceCount } from '../../../entities/FittingInstances/selectors';
import { selectNormalizedSchedules } from '../../../entities/Schedules/selectors';

const mapStateToProps = (familyId) => createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentFittingFamily(familyId),
  selectCurrentFilteredFittingInstances(familyId),
  selectNormalizedSizes(),
  selectNormalizedEndTypes(),
  selectNormalizedSchedules(),
  selectFittingInstanceCount(familyId),
  selectSidebarIsFiltering(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    fitting,
    data,
    sizes,
    endTypes,
    schedules,
    instanceCount,
    isFiltering,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || instanceCount == 0));
    return {
      ...rest,
      isLoading,
      data: !isLoading && data || [],
      editable: canCollaborate && hasValidLicense && fitting && !fitting.archivedFlag,
      fitting,
      fittingId: fitting && fitting.id || null,
      instanceCount,
      canCollaborate,
      hasValidLicense,
      sizes: sizes?.toList().toArray() || [],
      endTypes: endTypes?.toList().toArray() || [],
      schedules: schedules?.toList().toArray() || [],
      isFiltering,
    };
  },
);

const contentStyle = { width: 'fit-content' };

function FittingsEditGrid({ match }) {
  const reduxProps = useSelector(mapStateToProps(match.params.id));
  const dispatch = useDispatch();

  // dialog state
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [validation, setValidation] = useState(VALIDATION_FIELDS);
  const [editedFamily, setEditedFamily] = useState(reduxProps.fitting);

  // table state
  const [editedRows, resetEditedRows, currentEditingRow, isEditing, onBeforeCellEditShow, onCellChange, onCellEditComplete, handleRowAction, onDropdownComplete] = useOnCellEditComplete(reduxProps.data, convertToFittingInstanceType, reduxProps.editable, true);

  //dialog methods
  const handleEditedDialogChange = (newValue, field) => {
    // called on every edit, needs to be made into a custom hook of some kind
    let _editedRow = { ...editedFamily };
    _editedRow[`${field}`] = newValue;
    setEditedFamily(_editedRow);
  };

  const saveFittingAction = () => {
    // translated here to a format that can be sent to the api, replacing all object fields with their Id.
    setValidation(areFieldsValid(editedFamily));
    if (!doRequiredFieldsExist(editedFamily)) {
      return;
    }

    const editedRow = {
      id: editedFamily.id,
      name: editedFamily.name,
      description: editedFamily.description,
      unitSystem: editedFamily.unitSystem
    };

    dispatch(processEditFittingFamily(reduxProps.fitting.id, editedRow));
    setIsDialogOpen(false);
  };

  //Cell Bodies
  const textValueBody = (rowdata, field, placeholder = '') => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToFittingInstanceType(rowdata);

    return curRow && curRow[`${field}`] ?
      <Text >{curRow[`${field}`]}</Text> :
      <Text style={{ color: colors.gray[5], whiteSpace: 'nowrap' }} >{placeholder}</Text>;
  };

  const sBody = (rowdata, field, placeholder) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToFittingInstanceType(rowdata);

    return curRow && curRow[field] ?
      <ComplexCellRenderer value={curRow[field]} isCapitalized={true} paddingLeft='0px' /> :
      <Text className='text-cell-body' style={{ color: colors.gray[5] }}>{placeholder}</Text>;
  };

  const port2SizeBody = (rowdata) => {

    if (reduxProps.fitting.fittingCode.changesSize) {
      return (<>
        <Popup trigger={() => sBody(rowdata, 'port2Size', 'Select Size')} position='bottom center' on={['hover']} {...{ contentStyle }} >
          Port 2 Size must match Port 1 Size with this Fitting Family Code
        </Popup>
      </>);
    }

    return sBody(rowdata, 'port2Size', 'Select Size');
  };

  // Cell Editors
  const textCellEditor = (field) =>
    <InputText value={currentEditingRow[field]} keyfilter={ALLOWANCES.STOCKNO} style={{ width: '100%' }} onChange={(e) => onCellChange(e.target.value, field)} />;

  const sizeDropdown = (field) => {
    return <Dropdown
      value={currentEditingRow && currentEditingRow[field]}
      options={reduxProps.sizes}
      optionLabel='name'
      filter={true}

      itemTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}
      valueTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}

      onChange={(e) => onDropdownComplete.current(e.value, field, currentEditingRow)}
      style={{ width: '100%', height: '52px' }}
    />;
  };

  const scheduleDropdown = () => {
    return <Dropdown
      value={currentEditingRow.schedule?.id}
      options={reduxProps.schedules}
      optionLabel='value'
      optionValue='id'
      filter={true}

      itemTemplate={(value) => ComplexCellRenderer({ value: value, isCapitalized: true })}
      valueTemplate={() => ComplexCellRenderer({ value: currentEditingRow.schedule, isCapitalized: true })}

      onChange={(e) => onDropdownComplete.current(reduxProps.schedules.find(item => item.id == e.value), 'schedule', currentEditingRow)}
      style={{ width: '100%', height: '52px' }}
    />;
  };

  const endTypeDropdown = (field) => {
    return <Dropdown
      value={currentEditingRow && currentEditingRow[field]}
      options={reduxProps.endTypes}
      optionLabel='name'
      filter={true}

      itemTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}
      valueTemplate={(item) => ComplexCellRenderer({ value: item, isCapitalized: true })}

      onChange={(e) => onDropdownComplete.current(e.value, field, currentEditingRow)}
      style={{ width: '100%', height: '52px' }}
    />;
  };

  const getRowTool = (rowdata) => {
    const data = editedRows && editedRows.find(row => row.id == rowdata.id) || reduxProps.data.find((item) => item.id == rowdata.id);
    return (
      <EntityRowTools rowdata={data} handleRowAction={handleRowAction} />
    );
  };

  useEffect(() => {
    dispatch(processFetchIndividualFittingFamily(match.params.id));
    dispatch(processFetchAllSizes());
    dispatch(processFetchAllFittingCategories());
    dispatch(processFetchAllMaterials());
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={(reduxProps.fitting?.name) || PipingModuleFittingsEditPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.instanceCount} ${pluralize('filtered Fitting Instance', reduxProps.instanceCount)}` :
            `Showing ${reduxProps.instanceCount} ${pluralize('Fitting Instance', reduxProps.instanceCount)} Total`}
        />
        <Options
          updateEntities={() => saveCurrentIsulationInstanceChanges(
            editedRows,
            (data) => { dispatch(processSaveFittingInstances(reduxProps.fittingId, data)); resetEditedRows(); },
            reduxProps.defaultUnitSystem
          )}
          isEditingGrid={isEditing}
          isLoading={reduxProps.isLoading}
          pendingValidChanges={editedRows.length > 0}

          editEntity={() => { setEditedFamily(convertToFittingFamilyType(reduxProps.fitting)); setIsDialogOpen(true); }}
          isArchived={reduxProps.fitting?.archivedFlag}
          archiveEntity={() => dispatch(processArchiveFittingFamilies([reduxProps.fittingId]))}
          unarchiveEntity={() => dispatch(processUnarchiveFittingFamilies([reduxProps.fittingId]))}

          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
          canFilter={true}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          reorderableColumns
          value={reduxProps.data}
          tableStyle={{ minWidth: '55rem' }}
          size='normal'
          editMode='cell'
          rowClassName={(data) => data && rowStyles(data, editedRows)}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column header='' style={{ width: '2%' }} body={(rowdata) => getRowTool(rowdata)} ></Column>
          <Column field='schedule' header='Schedule' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => scheduleDropdown()} body={(rowdata) => sBody(rowdata, 'schedule', 'Select Schedule')} style={{ width: reduxProps.fitting?.fittingCode?.endCount > 2 ? '10%' : '12%' }} ></Column>
          <Column field='cadModelName' style={{ width: reduxProps.fitting?.fittingCode?.endCount > 2 ? '10%' : '12%' }} header='CAD Model Name' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => textCellEditor('cadModelName')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => textValueBody(rowdata, 'cadModelName', 'CAD Model Name')} sortable ></Column>
          <Column field='port0Size' header={() => PortColumnHeader('PORT0', 'Size')} onBeforeCellEditShow={onBeforeCellEditShow} editor={() => sizeDropdown('port0Size')} body={(rowdata) => sBody(rowdata, 'port0Size', 'Select Size')} style={{ width: reduxProps.fitting?.fittingCode?.endCount > 2 ? '10%' : '12%' }} ></Column>
          <Column field='port0EndType' header={() => PortColumnHeader('PORT0', 'End Type')} onBeforeCellEditShow={onBeforeCellEditShow} editor={() => endTypeDropdown('port0EndType')} body={(rowdata) => sBody(rowdata, 'port0EndType', 'Select EndType')} style={{ width: reduxProps.fitting?.fittingCode?.endCount > 2 ? '10%' : '12%' }} ></Column>
          <Column field='port1Size' header={() => PortColumnHeader('PORT1', 'Size')} onBeforeCellEditShow={onBeforeCellEditShow} editor={() => sizeDropdown('port1Size')} body={(rowdata) => sBody(rowdata, 'port1Size', 'Select Size')} style={{ width: reduxProps.fitting?.fittingCode?.endCount > 2 ? '10%' : '12%' }} ></Column>
          <Column field='port1EndType' header={() => PortColumnHeader('PORT1', 'End Type')} onBeforeCellEditShow={onBeforeCellEditShow} editor={() => endTypeDropdown('port1EndType')} body={(rowdata) => sBody(rowdata, 'port1EndType', 'Select End Type')} style={{ width: reduxProps.fitting?.fittingCode?.endCount > 2 ? '10%' : '12%' }} ></Column>
          { reduxProps.fitting?.fittingCode?.endCount > 2 &&
            [
              <Column field='port2Size' header={() => PortColumnHeader('PORT2', 'Size')} onBeforeCellEditShow={onBeforeCellEditShow} editor={() => sizeDropdown('port2Size')} body={(rowdata) => port2SizeBody(rowdata, 'port2Size', 'Select Size')} style={{ width: reduxProps.fitting?.fittingCode?.endCount > 2 ? '10%' : '12%' }} key='port21' ></Column>,
              <Column field='port2EndType' header={() => PortColumnHeader('PORT2', 'End Type')} onBeforeCellEditShow={onBeforeCellEditShow} editor={() => endTypeDropdown('port2EndType')} body={(rowdata) => sBody(rowdata, 'port2EndType', 'Select End Type')} style={{ width: reduxProps.fitting?.fittingCode?.endCount > 2 ? '10%' : '12%' }} key='port22' ></Column>
            ]
          }
          <Column field='stockno' style={{ width: reduxProps.fitting?.fittingCode?.endCount > 2 ? '10%' : '12%' }} header='Stock No' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => textCellEditor('stockno')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => textValueBody(rowdata, 'stockno', 'Stock No')} sortable ></Column>
          <Column field='mccsCode' style={{ width: reduxProps.fitting?.fittingCode?.endCount > 2 ? '10%' : '12%' }} header='MCCS Code' onBeforeCellEditShow={onBeforeCellEditShow} editor={() => textCellEditor('mccsCode')} onCellEditComplete={(e) => onCellEditComplete.current(e)} body={(rowdata) => textValueBody(rowdata, 'mccsCode', 'MCCS Code')} sortable ></Column>
        </DataTable>
      </Flex>
      <FittingFamilyDialog
        currentEditingRow={editedFamily}
        isDialogOpen={isDialogOpen}
        editable={reduxProps.editable}
        handleEditedRowChange={handleEditedDialogChange}
        saveAction={saveFittingAction}
        cancelAction={() => {
          setIsDialogOpen(false);
        }}
        validation={validation}
      />
    </>);
}

export default withRouter(FittingsEditGrid);