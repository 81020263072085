import { List } from 'immutable';
import pluralize from 'pluralize';

import EntityRecord from '../../modules/entities/model';

export const ALLOWANCES = {
  NAME: /^(.{0,1024})$/,
};

export default class ParameterProfile extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
  },
  archivedFlag: {
    value: false,
  },
  parameterProfileParameters: {
    value: List(),
    isEntity: true,
    handleCopy: true,
    handleDeepCopy: true,
    handleCompare: true,
    handleDeepCompare: true,
  },
}) {
  get primary() {
    return this.name;
  }
  get primaryPlaceholder() {
    return 'No Name';
  }
  get secondary() {
    return (
      (this.parameterProfileParameters.size &&
        `${this.parameterProfileParameters.size} ${pluralize(
          'parameter',
          this.parameterProfileParameters.size
        )}`) ||
      undefined
    );
  }
  get secondaryPlaceholder() {
    return '0 parameters';
  }

  get summary() {
    return {
      primary: this.name,
      // secondary: `${this.parameterProfileParameters.size} ${pluralize('parameter', this.parameterProfileParameters.size)}`,
    };
  }
}
