import { createSelector } from 'reselect';
import { Map } from 'immutable';

import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';

export const selectNormalizedConfigProfileMapkeys = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.CONFIG_PROFILE_MAPKEYS.state)
);
