import { takeLatest, take, put, fork, all, select } from 'redux-saga/effects';
import get from 'lodash/get';

import EK from '../../../../entities/keys';

import {
  reloadModal,
  hideModal
} from '../../../../modules/modal/actions';

import {
  createModelTemplateTypes,
  copyModelTemplateTypes,
  editModelTemplateTypes,
  deleteModelTemplateTypes,
} from '../../../../entities/ModelTemplates/constants';

import { selectNormalizedModelTemplates } from '../../../../entities/ModelTemplates/selectors';

export function* handleCloseModelTemplateModal() {
  yield put(hideModal());
}

export function* watchCloseModelTemplateModalSaga() {
  yield takeLatest(
    [
      createModelTemplateTypes.SUCCESS,
      editModelTemplateTypes.SUCCESS,
      deleteModelTemplateTypes.SUCCESS,
    ],
    handleCloseModelTemplateModal
  );
}

export function* watchCopyModelTemplateSaga() {
  yield takeLatest(
    copyModelTemplateTypes.SUCCESS,
    function* ({ payload = {} }) {
      const templateId = `${get(payload, `${EK.MODEL_TEMPLATES.plural}[0].id`)}`;
      if (templateId) {

        let modelTemplates = yield select(selectNormalizedModelTemplates());
        let copiedModelTemplate = modelTemplates.get(templateId);
        while (!copiedModelTemplate) {
          yield take();
          modelTemplates = yield select(selectNormalizedModelTemplates());
          copiedModelTemplate = modelTemplates.get(templateId);
        }

        yield put(
          reloadModal('MODEL_TEMPLATE_MODAL', {
            modelTemplateId: copiedModelTemplate.id,
            copyEntity: false,
          })
        );
      }
    }
  );
}

// final output saga
export default function* main() {
  yield all([
    fork(watchCloseModelTemplateModalSaga),
    fork(watchCopyModelTemplateSaga),
  ]);
}
