import baseSchema from '../../modules/entities/utils/schema';

import EK from '../keys';

// prettier-ignore
const styleStateSchema = baseSchema(
  EK.STYLE_STATES.state
);

export default styleStateSchema;
