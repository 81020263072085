import { createSelector } from 'reselect';

import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';

export const selectNormalizedAppearanceStates = () => createSelector(
  selectEntities(),
  (entities) => entities.get(EK.APPEARANCE_STATES.state)
);
export const selectNormalizedAppearanceStatesList = () => createSelector(
  selectNormalizedAppearanceStates(),
  appearanceStates => appearanceStates.toList()
);
