import baseSchema from '../../modules/entities/utils/schema';

import EK from '../keys';

import GlobalCadSearchRuleSchema from '../GlobalCadSearchRules/schema';
import GlobalCadSearchRuleCriterionSchema from '../GlobalCadSearchRuleCriteria/schema';

const simplifiedRepRulesetRuleCriterionValueSchema = baseSchema(
  EK.SIMPLIFIED_REP_RULESET_RULE_CRITERION_VALUES.state,
  {
    globalCadSearchRuleCriterion: {
      schema: GlobalCadSearchRuleCriterionSchema,
      isId: true,
    },
    simplifiedRepRulesetRule: {
      isId: true,
    },
  }
);

const simplifiedRepRulesetRuleSchema = baseSchema(
  EK.SIMPLIFIED_REP_RULESET_RULES.state,
  {
    globalCadSearchRule: {
      schema: GlobalCadSearchRuleSchema,
      isId: true,
    },
    simplifiedRepRulesetRuleCriterionValues: {
      schema: [simplifiedRepRulesetRuleCriterionValueSchema],
      isId: true,
      idField: 'simplifiedRepRulesetRuleCriterionValueIds',
      isList: true,
    },
  }
);

simplifiedRepRulesetRuleCriterionValueSchema.define({
  simplifiedRepRulesetRule: simplifiedRepRulesetRuleSchema,
});

export { simplifiedRepRulesetRuleSchema };

export default simplifiedRepRulesetRuleCriterionValueSchema;
