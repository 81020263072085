import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = 'LAYER_STATES';

export const fetchAllLayerStateTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createLayerStateTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const copyLayerStateTypes = generateAPHTypes(
  resource,
  'COPY'
);

export const editLayerStateTypes = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteLayerStateTypes = generateAPHTypes(
  resource,
  'DELETE'
);