import EntityRecord, {
  FILTERS,
  VALIDATORS,
} from '../../modules/entities/model';

export const ALLOWANCES = {
  NAME: /^([\sa-zA-Z0-9_]{0,31})$/,
  KEYSTROKE: /^([^$].*)|\$f[1-9][1-2]?.*$/,
};

class Mapkey extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      VALIDATORS.IS_REQUIRED_STRING(entity.name) &&
      ALLOWANCES.NAME.test(entity.name),
    validateError:
      'Name can either be empty or be at most 31 characters in length, and can only contain letters, numbers, and \'_\'',
    filter: FILTERS.STRING,
  },
  keystroke: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      VALIDATORS.IS_REQUIRED_STRING(entity.keystroke) &&
      ALLOWANCES.KEYSTROKE.test(entity.keystroke),
    filter: FILTERS.STRING,
  },
  description: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  mapkeyAction: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) => VALIDATORS.IS_REQUIRED_STRING(entity.mapkeyAction),
  },
  configSettingCategory: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleSerialize: true,
    serializeOutputField: 'configSettingCategoryId',
  },

  // these are pseudo model properties, we're adding them here to make it easier for us to render rows
  // these are actually taken from the ConfigProfileMapkey model and thrown into here in the selector
  // however, because of this, they are not parsed like any other properties would be based on the current
  // architecture
  profileMapkey: {
    value: undefined,
  },
  isInCurrentProfile: {
    value: undefined,
  },
  hasMapkeyConflictInProfile: {
    value: false,
  },
}) {
  get primary() {
    return this.name;
  }
  get primaryPlaceholder() {
    return 'No Name';
  }
  get secondary() {
    return this.description;
  }
  get secondaryPlaceholder() {
    return 'No Description';
  }
}

export default Mapkey;
