import { List } from 'immutable';
import pluralize from 'pluralize';

import EK from '../keys';

import EntityRecord, { FILTERS } from '../../modules/entities/model';

export default class ConfigProfile extends EntityRecord({
  name: {
    value: null,
    handleCopy: entity => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  archivedFlag: {
    value: false,
  },
  [`${EK.CONFIG_PROFILE_VALUES.plural}Count`]: {
    value: 0,
  },
  [EK.CONFIG_PROFILE_VALUES.plural]: {
    value: List(),
    isEntity: true,
    handleCopy: true,
    handleDeepCopy: true,
    handleCompare: true,
    handleDeepCompare: true,
  },
  [`${EK.CONFIG_PROFILE_MAPKEYS.plural}Count`]: {
    value: 0,
  },
  [EK.CONFIG_PROFILE_MAPKEYS.plural]: {
    value: List(),
    isEntity: true,
    handleDeepCopy: true,
    handleCompare: true,
    handleDeepCompare: true,
  }
}) {
  get summary() {
    const summaryCount = this.get(`${EK.CONFIG_PROFILE_VALUES.plural}Count`);
    const summaryText = summaryCount > 0 ?
      `${summaryCount} ${pluralize('setting', summaryCount)}` :
      undefined;
    const mapkeyCount = this.get(`${EK.CONFIG_PROFILE_MAPKEYS.plural}Count`);
    const mapkeyText = mapkeyCount > 0 ?
      `${mapkeyCount} ${pluralize('mapkeys', mapkeyCount)}` :
      undefined;
    return {
      primary: this.name,
      secondary: `${summaryText || ''}${summaryText && mapkeyText && ', ' || ''}${mapkeyText || ''}`,
    };
  }
}
