import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = 'ORIENTATIONS';

export const fetchAllOrientationsTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createOrientationTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const saveOrientationsTypes = generateAPHTypes(
  resource,
  'COLLECTION'
);
