import { List } from 'immutable';
import EntityRecord from '../../modules/entities/model';

class SimplifiedRepRulesetRule extends EntityRecord({
  isOrLogic: {
    value: true,
  },
  sortOrder: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
  },
  globalCadSearchRule: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    handleDeepSerialize: true,
    serializeOutputField: 'globalCadSearchRuleId',
  },
  simplifiedRepRulesetId: {
    value: null,
  },
  simplifiedRepRulesetRuleCriterionValues: {
    value: List(),
    isEntity: true,
    handleCopy: true,
    handleDeepCopy: true,
    handleCompare: true,
    handleDeepCompare: true,
    // handleSerialize: true,
    handleSerialize: (entity) =>
      entity.simplifiedRepRulesetRuleCriterionValues
        .map((value) => ({
          ...value.serialize(),
          id: value.id ? value.id : undefined,
        }))
        .toArray(),
    // handleDeepSerialize: true,
    serializeOutputField:
      'simplifiedRepRulesetRuleCriterionValuesAttributes',
  },
}) {
  get primary() {
    return this.globalCadSearchRule && this.globalCadSearchRule.name;
  }
  get primaryPlaceholder() {
    return 'No Global Rule Name';
  }
  get secondary() {
    const output = this.simplifiedRepRulesetRuleCriterionValues
      .sortBy(
        (criterion) =>
          criterion.globalCadSearchRuleCriterion &&
          criterion.globalCadSearchRuleCriterion.sortOrder
      )
      .map((criterion) => criterion.value)
      .filter((value) => !!value);
    return output.size > 0 ? output : null;
  }
  get secondaryPlaceholder() {
    return 'No Criteria';
  }
}

export default SimplifiedRepRulesetRule;
