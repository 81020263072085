import EK from '../keys';

import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = EK.NOTE_PROFILES.state;

export const fetchAllNoteProfilesTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const fetchIndividualNoteProfileTypes = generateAPHTypes(resource, 'FETCH');

export const createNoteProfileTypes = generateAPHTypes(resource, 'CREATE');

export const copyNoteProfileTypes = generateAPHTypes(resource, 'COPY');

export const editNoteProfileTypes = generateAPHTypes(resource, 'EDIT');

export const deleteNoteProfileTypes = generateAPHTypes(resource, 'DELETE');

export const archiveNoteProfilesTypes = generateAPHTypes(resource, 'ARCHIVE');

export const unarchiveNoteProfilesTypes = generateAPHTypes(resource, 'UNARCHIVE');
