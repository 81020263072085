import { SpecPipeInstancesType, EditableSpecPipeInstancesType } from '../../../entities/SpecPipeInstances/types';
import { SpecPipeFamilyType } from '../../../entities/PipeFamilies/types';
import { PipeInstancesType } from '../../../entities/PipeInstances/types';
import { List } from 'immutable';

export function convertToSpecPipeInstanceType (item: any): EditableSpecPipeInstancesType {
  if(item?.pipeInstance?.id) {
    return {
      id: item.id,
      pipeInstance: item.pipeInstance,
      pipeFamilyId: item.pipeFamilyId,
      spec: item.spec,
      stockno: item.stockno || item.pipeInstance.stockno,
      mccsCode: item.mccsCode || item.pipeInstance.mccsCode,
      isSelectedForSpec: !!item.isSelectedForSpec,
      isEdited: !!item.isEdited,
      isDeleted: !!item.isDeleted,
      isCreated: !!item.isCreated,
      originalRow: item.originalRow || item
    };
  }
  return {
    id: item.id,
    pipeInstance: item,
    pipeFamilyId: item.pipeFamilyId,
    spec: item.spec,
    stockno: item.stockno,
    mccsCode: item.mccsCode,
    isSelectedForSpec: !!item.isSelectedForSpec,
    isEdited: !!item.isEdited,
    isDeleted: !!item.isDeleted,
    isCreated: !!item.isCreated,
    originalRow: item.originalRow || item
  };
}

export function convertToSpecPipeFamilyType ({
  id,
  isSelectedForSpec,
  isPrioritized,
  name,
  material,
  pipeInstances,
  selectedPipeInstancesForSpec,
  index,
  originalData,
  isEdited = false,
  isDeleted = false,
  isCreated = false,
}: SpecPipeFamilyType) : SpecPipeFamilyType {
  return {
    id,
    isSelectedForSpec,
    isPrioritized,
    name,
    material,
    pipeInstances,
    selectedPipeInstancesForSpec,
    index,
    originalData,
    isEdited,
    isDeleted,
    isCreated,
  };
}

// editedOrder will be handles in the 'saveaction' method in the body.js file to simplify matters since it will just be getting filtered and JSON.stringify'd
// spec_pipe_instances_attributes: [
//   : id,
//   : pipe_instance_id,
//   : stockno,
//   : mccs_code,
//   : _destroy
// ]})[: spec_pipe_instances_attributes]
export const saveCurrentPipingSpecPipeFamiliesChanges = (
  editedRows: Record<string, SpecPipeFamilyType>,
  specPipeInstances: Array<SpecPipeInstancesType>,
  saveAction: any,
) => {
  const collectionsdata: any = [];

  for (const [key, value] of Object.entries(editedRows)) {
    if(value.isSelectedForSpec != value.originalData.isSelectedForSpec) {
      const pipeFamilyData = value.isSelectedForSpec ?
        {id: key, data: value.pipeInstances.toArray().map(instance => ({pipe_instance_id: instance.id, stockno: instance.stockno, mccsCode: instance.mccsCode}))} :
        {id: key, data: specPipeInstances.filter(({ pipeFamilyId }) => pipeFamilyId.id == key).map(instance => ({ id: instance.id, _destroy: '1' }))};
      collectionsdata.push(pipeFamilyData);
    }
  }

  saveAction(collectionsdata);
};
