import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = 'SIMPLIFIED_REP_RULESET_RULES';

export const createSimplifiedRepRulesetRuleTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const editSimplifiedRepRulesetRuleTypes = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteSimplifiedRepRulesetRuleTypes = generateAPHTypes(
  resource,
  'DELETE'
);
