import EntityRecord, { VALIDATORS, FILTERS } from '../../modules/entities/model';

export const ALLOWANCES = {
  VALUE: /^(.{0,255})$/,
};

class Schedule extends EntityRecord({
  value: {
    value: null,
    handleCopy: (entity) => `${entity.value} COPY`,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      VALIDATORS.IS_REQUIRED_STRING(entity.value) &&
      ALLOWANCES.VALUE.test(entity.value),
    validateError:
      'A valid value with no more than 255 characters must be present',
    filter: FILTERS.STRING,
  },
  description: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
}) {
  get primary() {
    return this.value;
  }
  get primaryPlaceholder() {
    return 'No Value';
  }
  get secondary() {
    return this.description;
  }
  get secondaryPlaceholder() {
    return '--';
  }
}

export default Schedule;
