import EntityRecord, {
  FILTERS,
  VALIDATORS,
} from '../../modules/entities/model';

class ParameterProfileParameter extends EntityRecord({
  parameterProfile: {
    value: null,
    isEntity: true,
    handleCopy: true,
  },
  parameterProfileId: {
    value: null,
    handleCopy: true,
  },
  parameter: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'parameterId',
    validate: (entity) => VALIDATORS.IS_REQUIRED_ENTITY(entity.parameter),
  },
  defaultValue: {
    value: undefined,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) => {
      // we can never have a parameter profile parameter without a parameter
      if (!entity.parameter) return false;

      const value = entity.defaultValue;
      // because all parameters are optional, null and undefined are acceptable values across the board
      // either of these values means that we will default to the setting's defaultValue
      if (value === null || value === undefined) return true;

      // validate this defaultValue against the parent parameter's valueType
      return entity.parameter.validateValueAgainstType(value);
    },
    validateError: (entity) => {
      if (!entity.parameter) {
        return 'A Parameter must be selected before a default value can be set';
      } else {
        return entity.parameter.validateErrorAgainstType;
      }
    },
  },
  paramIsLocked: {
    value: false,
    handleCompare: true,
    handleCopy: true,
    handleSerialize: true,
    filter: FILTERS.BOOLEAN,
  },
  paramIsDesignated: {
    value: false,
    handleCompare: true,
    handleCopy: true,
    handleSerialize: true,
    filter: FILTERS.BOOLEAN,
  },
}) {
  get editableDefaultValue() {
    if (!this.parameter) return null;
    if (
      this.defaultValue === undefined &&
      this.parameter.defaultValue === undefined
    ) {
      return undefined;
    }

    const dv = this.defaultValue || this.parameter.defaultValue || null;
    switch (this.parameter.valueType) {
    case 'bool': {
      return dv === null ? null : `${dv}`;
    }
    default: {
      // 'list', 'double', 'integer', 'string'
      return dv || null;
    }
    }
  }

  setParameter(parameterId) {
    return this.set('parameter', parameterId).set('defaultValue', null);
  }

  setEditableDefaultValue(value) {
    if (value === undefined) {
      return this.set('defaultValue', undefined);
    } else if (!this.parameter) {
      return this.set('defaultValue', null);
    } else {
      switch (this.valueType) {
      case 'bool': {
        return this.set('defaultValue', value === null ? null : `${value}`);
      }
      default:
        // 'list', 'double', 'string', 'integer'
        return this.set('defaultValue', value);
      }
    }
  }
}

export default ParameterProfileParameter;
