import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { reset, submit } from 'redux-form/immutable';
import { createSelector, createStructuredSelector } from 'reselect';
import { List, Map } from 'immutable';
import styled from 'styled-components';

import colors from '../../../../assets/themes/base/colors';
import radii from '../../../../assets/themes/base/radii';
import space from '../../../../assets/themes/base/space';
import transitions from '../../../../assets/themes/base/transitions';

import useBoundingclientrectRef from '@rooks/use-boundingclientrect-ref';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';
import Flex from '../../../../components/common/Flex';
import Icon from '../../../../components/common/Icon';
import Loading from '../../../../components/common/Loading';
import Text from '../../../../components/common/Text';
import Typography from '../../../../components/common/Typography';

import ConfirmDeleteForm from '../../../form/templates/ConfirmDeleteForm';

import ConfigProfileNameForm from '../../../form/templates/ConfigProfileNameForm';
import ConfigProfileSettingsForm from '../../../form/templates/ConfigProfileSettingsForm';
import ConfigProfileSettingsSearchForm from '../../../form/templates/ConfigProfileSettingsSearchForm';
import ConfigProfileSettingsFilterForm from '../../../form/templates/ConfigProfileSettingsFilterForm';

import NavigationListItem from '../../../../entities/ConfigSettingCategories/components/NavigationListItem';

import {
  selectIsFetchingInModal,
  selectIsPerformingInModal,
} from '../../../../modules/utility/selectors';

import ConfigProfileModel from '../../../../entities/ConfigProfiles/model';
import {
  processCreateConfigProfile,
  processCopyConfigProfile,
  processEditConfigProfile,
  processDeleteConfigProfile,
} from '../../../../entities/ConfigProfiles/actions';
import { selectCurrentConfigProfile } from '../../../../entities/ConfigProfiles/selectors';

import {
  processFetchAllConfigSettingsForCategory,
  processSearchForConfigSettings,
} from '../../../../entities/ConfigSettings/actions';

import { selectNormalizedConfigProfileValues } from '../../../../entities/ConfigProfileValues/selectors';

import {
  selectConfigProfileEditorModalIsReady,
  selectConfigProfileCategories,
} from './selectors';
import {
  prepareConfigProfileEditorModal,
  saveConfigProfile,
} from './actions';

import injectReducer from '../../../../utils/reducers/injectReducer';
import injectSaga from '../../../../utils/sagas/injectSaga';

import reducer from './reducer';
import saga from './sagas';

const ConfirmDeleteModal = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Modal = styled(Flex)`
  width: 95%;
  height: 100%;
  margin: auto;
  flex-direction: row;
  align-items: center;
  background: ${colors.gray[1]};
  border-radius: ${radii[2]};
  overflow: hidden;
`;

const Header = styled(Flex)`
  display: flex;
  flex-direction: row;
  margin-top: ${space[6]};
  padding: ${space[4]} ${space[6]} ${space[4]} calc(${space[6]} - ${space[3]});
  align-items: center;
  background: ${colors.gray[1]};
`;

const HeaderText = styled(Flex)`
  padding-left: ${space[3]};
  padding-right: ${space[3]};
  height: 4rem;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
`;

const Navigation = styled(Box)`
  overflow: auto;
`;

const Content = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  position: relative;

  border-top-left-radius: ${radii[2]};
  background: ${colors.white};
`;

const DynamicContentContainer = styled(Box)`
  flex-grow: 1;
`;

const DynamicContent = styled(Box)`
  position: absolute;
  bottom: 0;
  left: ${({ left }) => left};
  right: ${({ right }) => right};
`;

const ContentHeader = styled(Flex)`
  flex-direction: row;
  align-items: center;

  border-bottom: 1px solid ${colors.gray[0]};
`;

const TemplatesButton = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-right: 1px solid ${colors.gray[0]};
  background-color: transparent;
  cursor: pointer;
  color: ${colors.gray[5]};

  transition: background-color ${transitions.speed.fast}, color ${transitions.speed.fast};

  &:hover {
    background-color: ${colors.gray[0]};
    color: ${colors.gray[7]};
  }
`;

const HomeNavContainer = styled(Flex)`
  border-right: 1px solid ${colors.gray[0]};
`;

const ProfileSummaryButton = styled(Flex)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 4rem;
  cursor: pointer;
  color: ${colors.gray[6]};
  padding: 0 ${space[3]};
  background-color: transparent;

  transition: background-color ${transitions.speed.fast}, color ${transitions.speed.fast};

  &:hover {
    background-color: ${colors.gray[0]};
    color: ${colors.gray[7]};
  }
`;

const GrowingSkeleton = styled(Box)`
  flex-grow: 1;
`;

const HomeNav = ({ reset, isLoading }) => (
  <HomeNavContainer flexDirection='column' justifyContent='center' width={320}>
    <Flex flexDirection='row' alignItems='center'>
      {
        isLoading ?
          <Flex width='100%' height='4rem' flexDirection='row' justifyContent='center' alignItems='center' pl={3} pr={3}>
            <Skeleton width='1rem' height='1rem' />
            <GrowingSkeleton>
              <Text fontSize={2} ml={2}><Skeleton /></Text>
            </GrowingSkeleton>
          </Flex> :
          <ProfileSummaryButton onClick={reset}>
            <Icon name='dynamic-feed' fontSize={4} />
            <Text fontSize={2} ml={2}>Profile Summary</Text>
          </ProfileSummaryButton>
      }
      {/* <TemplatesButton><Icon name='apps' fontSize={4} /></TemplatesButton> */}
    </Flex>
  </HomeNavContainer>
);

const SearchAndFilterContainer = styled(Flex)`
  flex-grow: 1;
`;

const SearchFormContainer = styled(Box)`
  flex-grow: 1;
`;

const SearchAndFilterSkeleton = () => (
  <SearchAndFilterContainer flexDirection='row' pl={3} pr={6}>
    <Skeleton width='1rem' height='1rem' />
    <SearchFormContainer pl={3} pr={3}>
      <Skeleton width='40%' />
    </SearchFormContainer>
    <Skeleton width='1rem' height='1rem' />
  </SearchAndFilterContainer>
);

const createSelectInitialValuesSelector = () => createSelector(
  selectNormalizedConfigProfileValues(),
  (_, configProfileId) => configProfileId,
  (profileValues, configProfileId) => profileValues.reduce((initialValues, profileValue) => {
    if (profileValue.get(EK.CONFIG_PROFILES.single) === configProfileId) {
      if (profileValue.multipleAllowed) {
        if (initialValues.has(profileValue.get(EK.CONFIG_SETTINGS.single))) {
          return initialValues.set(
            profileValue.get(EK.CONFIG_SETTINGS.single),
            initialValues.get(profileValue.get(EK.CONFIG_SETTINGS.single))
              .push(Map({ id: profileValue.id, value: profileValue.value }))
          );
        } else {
          return initialValues.set(
            profileValue.get(EK.CONFIG_SETTINGS.single),
            List([ Map({ id: profileValue.id, value: profileValue.value }) ])
          );
        }
      } else {
        return initialValues.set(profileValue.get(EK.CONFIG_SETTINGS.single), profileValue.value);
      }
    } else {
      return initialValues;
    }
  }, Map())
);

const emptyConfigProfile = new ConfigProfileModel();

const ConfigProfileEditorModal = ({
  configProfileId,
  isReady,
  isFetching,
  isPerforming,
  copyEntity,
  currentProfile,
  profileCategories,
  initialSettingCategoryId = null,
  prepareConfigProfile,
  createConfigProfile,
  editConfigProfile,
  searchForSetting,
  saveSettings,
  onCreateConfigProfile,
  onCopyConfigProfile,
  onConfirmDeleteConfigProfile,
  onSaveSettings,
  resetSettingFilters,
  resetSettingSearch,
}) => {
  useEffect(() => {
    prepareConfigProfile(configProfileId, copyEntity);
  }, []);

  const [dynamicContentRef, dynamicContentRect, updateContentRectHeight] = useBoundingclientrectRef();

  const [modalState, setModalState] = useState({
    settingCategoryId: initialSettingCategoryId,
    searchTerm: null,
    showFilterForm: false,
    showConfirmDeleteForm: false,
  });
  const setSettingCategoryId = useCallback(settingCategoryId => {
    setModalState(prev => ({ ...prev, settingCategoryId, searchTerm: null, showFilterForm: false }));
  }, [setModalState]);
  const setSearchTerm = useCallback(searchTerm => {
    setModalState(prev => ({ ...prev, settingCategoryId: null, searchTerm, showFilterForm: !!searchTerm }));
  }, [setModalState]);
  const toggleFilterForm = useCallback(() => {
    setModalState(prev => ({ ...prev, showFilterForm: !prev.showFilterForm }));
  }, [setModalState]);
  const clearModalState = useCallback(() => {
    setModalState({ settingCategoryId: null, searchTerm: null, showFilterForm: false, showConfirmDeleteForm: false });
  }, [setModalState]);
  const toggleConfirmDeleteForm = useCallback(() => {
    setModalState(prev => ({ ...prev, showConfirmDeleteForm: !prev.showConfirmDeleteForm }));
  }, [setModalState]);
  const onConfirmDelete = useCallback(() => {
    onConfirmDeleteConfigProfile(configProfileId);
  }, [onConfirmDeleteConfigProfile, configProfileId]);
  const onCopy = useCallback(data => {
    onCopyConfigProfile(configProfileId, data);
  }, [copyEntity, configProfileId, onCopyConfigProfile]);
  // whenever the searchTerm changes, do a search
  useEffect(() => {
    if (modalState.searchTerm) {
      searchForSetting(modalState.searchTerm);
    }
  }, [modalState.searchTerm]);

  useEffect(() => {
    resetSettingFilters();
  }, [modalState.searchTerm, modalState.settingCategoryId]);

  useEffect(() => {
    if (modalState.settingCategoryId) {
      resetSettingSearch();
    }
  }, [modalState.settingCategoryId]);

  const selectInitialValuesSelector = useMemo(
    createSelectInitialValuesSelector,
    []
  );

  const isNewConfigProfile = !configProfileId || copyEntity;

  const initialValues = useSelector(state => selectInitialValuesSelector(state, configProfileId));

  const currentProfileName = useMemo(() => currentProfile && currentProfile.name || undefined, [currentProfile]);
  const [isEditingCurrentProfileName, setIsEditingCurrentProfileName] = useState(isNewConfigProfile);
  useEffect(() => {
    if (currentProfileName) {
      setIsEditingCurrentProfileName(false);
    }
  }, [currentProfileName]);
  const openEditCurrentProfileName = useCallback(() => {
    setIsEditingCurrentProfileName(true);
  }, [setIsEditingCurrentProfileName]);
  const saveCurrentProfileName = useCallback(data => {
    if (configProfileId) {
      editConfigProfile(configProfileId, data);
    }
  }, [configProfileId, editConfigProfile]);

  return !isReady ? <Loading /> : (
    modalState.showConfirmDeleteForm ? (
      <ConfirmDeleteModal>
        <ConfirmDeleteForm deletePhrase={currentProfile && currentProfile.name} isPerforming={isPerforming} onRDXSubmit={onConfirmDelete} onCancel={toggleConfirmDeleteForm} />
      </ConfirmDeleteModal>
    ) : (
      <Modal>
        <Flex flexDirection='column' justifyContent='flex-end' width='15rem' height='100%' p={6}>
          <Typography.F1
            label='Settings'
            sub={profileCategories.totalEditedValuesCount > 0 ? `${profileCategories.totalEditedValuesCount} Unsaved Changes` : 'Saved Values'} capitalize>
            <Text as='span' color={profileCategories.totalEditedValuesCount > 0 ? 'primary.4' : undefined}>{ profileCategories.totalValuesCount }</Text>
          </Typography.F1>
        </Flex>
        <Flex flexDirection='column' width='calc(100% - 15rem)' height='100%'>
          <Header>
            {
              (isEditingCurrentProfileName || copyEntity) ?
                <ConfigProfileNameForm
                  initialValues={currentProfile || emptyConfigProfile}
                  onRDXSubmit={isNewConfigProfile ? (copyEntity ? onCopy : onCreateConfigProfile) : saveCurrentProfileName}
                  enableReinitialize={copyEntity}
                  isCopying={copyEntity}
                  showSubmitButton={configProfileId && !copyEntity}
                /> :
                <HeaderText>
                  <Text as='span' color='gray.7' fontSize={5} $ellipsis>{ currentProfile && currentProfile.name || 'Loading...' }</Text>
                  <Button
                    primary
                    transparent
                    subtle
                    type="button"
                    icon='edit'
                    large
                    disabled={isPerforming}
                    onClick={openEditCurrentProfileName}
                    ml={2}
                  />
                </HeaderText>
            }
            {
              !isNewConfigProfile &&
              <Button
                error
                transparent
                subtle
                type="button"
                icon='delete'
                large
                disabled={isPerforming}
                onClick={toggleConfirmDeleteForm}
                mr={2}
              />
            }
            {
              isNewConfigProfile ?
                <Button primary onClick={createConfigProfile} disabled={isPerforming}>Create</Button> :
                <Button primary onClick={saveSettings} disabled={isPerforming || !profileCategories.totalEditedValuesCount}>Save</Button>
            }
          </Header>
          <Content>
            <ContentHeader>
              <HomeNav reset={clearModalState} isLoading={isNewConfigProfile} />
              {
                isNewConfigProfile ?
                  <SearchAndFilterSkeleton /> :
                  <SearchAndFilterContainer flexDirection='row'>
                    <SearchFormContainer>
                      <ConfigProfileSettingsSearchForm
                        isPerforming={isPerforming}
                        onRDXSubmit={setSearchTerm}
                      />
                    </SearchFormContainer>
                    <Box>
                      <ConfigProfileSettingsFilterForm toggleExpandForm={toggleFilterForm} formExpanded={modalState.showFilterForm} />
                    </Box>
                  </SearchAndFilterContainer>
              }
            </ContentHeader>
            <DynamicContentContainer ref={dynamicContentRef} />
            <DynamicContent height={dynamicContentRect && dynamicContentRect.height} width={320} left='0'>
              {
                dynamicContentRect && dynamicContentRect.height && (
                  <Navigation height={dynamicContentRect.height}>
                    {
                      profileCategories.categories.map(category =>
                        <NavigationListItem
                          key={category.id}
                          category={category}
                          active={category.id === modalState.settingCategoryId}
                          onSelect={setSettingCategoryId}
                          isLoading={isNewConfigProfile}
                        />
                      )
                    }
                  </Navigation>
                )
              }
            </DynamicContent>
            <DynamicContent height={dynamicContentRect && dynamicContentRect.height} width={dynamicContentRect && (dynamicContentRect.width - 320)} right='0'>
              {
                dynamicContentRect && dynamicContentRect.height && (
                  !isNewConfigProfile ?
                    <ConfigProfileSettingsForm
                      height={dynamicContentRect.height}
                      categoryId={modalState.settingCategoryId}
                      searchTerm={modalState.searchTerm}
                      initialValues={initialValues}
                      enableReinitialize
                      isFetching={isFetching}
                      isPerforming={isPerforming}
                      onSubmit={onSaveSettings}
                    /> :
                    <Flex
                      flexDirection='column'
                      justifyContent='center'
                      alignItems='center'
                      height={dynamicContentRect.height}
                    >
                      <Text fontSize={3} color='gray.6' mb={2}>
                      Create a profile to get started
                      </Text>
                      <Text fontSize={2} color='gray.6'>
                      All you need to do is give it a name
                      </Text>
                    </Flex>
                )
              }
            </DynamicContent>
          </Content>
        </Flex>
      </Modal>
    )
  );
};

const mapStateToProps = createStructuredSelector({
  isReady: selectConfigProfileEditorModalIsReady(),
  isFetching: selectIsFetchingInModal(),
  isPerforming: selectIsPerformingInModal(),
  currentProfile: selectCurrentConfigProfile(),
  profileCategories: selectConfigProfileCategories(),
});

const mapDispatchToProps = dispatch => ({
  prepareConfigProfile(configProfileId, copyEntity) { dispatch(prepareConfigProfileEditorModal(configProfileId, copyEntity)); },
  onCreateConfigProfile(data) { dispatch(processCreateConfigProfile(data)); },
  onCopyConfigProfile(configProfileId, data) { dispatch(processCopyConfigProfile({ ...data, id: configProfileId })); },
  editConfigProfile(configProfileId, data) { dispatch(processEditConfigProfile(configProfileId, data)); },
  onConfirmDeleteConfigProfile(configProfileId) { dispatch(processDeleteConfigProfile(configProfileId)); },
  fetchSettingsForCategory(categoryId) { dispatch(processFetchAllConfigSettingsForCategory(categoryId)); },
  searchForSetting(searchTerm) { dispatch(processSearchForConfigSettings(searchTerm)); },
  saveSettings() { dispatch(submit(EK.CONFIG_SETTINGS.state)); },
  resetSettingFilters() { dispatch(reset(`filters.${EK.CONFIG_SETTINGS.state}`)); },
  resetSettingSearch() { dispatch(reset(`search.${EK.CONFIG_SETTINGS.state}`)); },
  onSaveSettings() { dispatch(saveConfigProfile()); },
  createConfigProfile() { dispatch(submit(EK.CONFIG_PROFILES.state)); },
});

const enhance = compose(
  injectReducer({ key: `${EK.CONFIG_SETTINGS.state}Modal`, reducer }),
  injectSaga({ key: `${EK.CONFIG_SETTINGS.state}Modal`, saga }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(ConfigProfileEditorModal);
