import validateRules from '../../utils/validation/validateRules';

export const rules = {
  note: {
    presence: {
      message: 'Must not be empty',
      validator: value => value !== null && value !== undefined && value !== '',
    },
  },
};

export const validations = {
  note: validateRules(rules.note),
};
