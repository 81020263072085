import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';
import { List } from 'immutable';

import EK from '../keys';
import selectEntities from '../../modules/entities/selectors';
import SpecFittingInstanceSchema from './schema';
import { selectSidebarIsFiltering } from '../../routes/Dashboard/selectors';
import { selectCurrentFittingInstances } from '../FittingInstances/selectors';
import { convertToSpecFittingInstanceType } from '../../routes/Piping/SpecsFittingsEdit/actions';
import { selectCurrentSpec } from '../Specs/selectors';

export const selectNormalizedSpecFittingInstances = () => createSelector(
  selectEntities(),
  entities => entities.get('specFittingInstances')
);

export const selectCurrentNormalizedSpecFittingInstances = (specId) =>
  createSelector(
    selectNormalizedSpecFittingInstances(),
    instances => instances.toList().filter(item => item.spec == specId)
  );

const selectCurrentSpecFittingInstancesFromSpec = (specId) => createSelector(
  selectCurrentSpec(specId),
  (spec) => spec?.specFittingInstances || List()
);

const specFilterForm = formValueSelector(`filters.${EK.SPEC_FITTING_INSTANCES.state}`);

function removeDuplicateFromSpecFittingInstancesArray(arr) {
  // borrowed this from https://www.geeksforgeeks.org/how-to-get-all-unique-values-remove-duplicates-in-a-javascript-array/, it's pretty slick.
  let outputArray = arr.filter(function (v, i, self) {
    if (v.fittingInstance) {
      return v?.fittingInstance.id && i == self.findIndex((item) => item?.fittingInstance?.id ? item.fittingInstance.id == v.fittingInstance.id : item.id == v.fittingInstance.id);
    }
    return v?.id && i == self.findIndex((item) => item?.fittingInstance?.id ? item.fittingInstance.id == v.id : item.id == v.id);
  });

  return outputArray;
}

// gets all of the instances for a fitting family, concatenates that list with the list of specfittinginstances, and then removes the duplicates
export const selectCurrentFilteredSpecFittingInstances = (specId, fittingId) => createSelector(
  selectSidebarIsFiltering(),
  selectCurrentSpecFittingInstancesFromSpec(specId),
  selectCurrentFittingInstances(fittingId),
  state => specFilterForm(state, 
    'schedule',
    'cadModelName',
    'port0Size',
    'port0EndType',
    'port1Size',
    'port1EndType',
    'port2Size',
    'port2EndType',
    'stockno',
    'mccsCode',
    'boltNutFamily' ),
  (isFiltering, instances, fittingInstances, query) => {
    const specFittingInstances = instances && instances.filter(item => typeof (item.fittingFamilyId) == 'string' ? item.fittingFamilyId == fittingId : item.fittingFamilyId?.id == fittingId);
    const denSPI = specFittingInstances && isFiltering ? specFittingInstances.filter(i => i.doesMatchQuery(query, { normalized: false, searchAll: true })).toArray() : specFittingInstances.toArray() || [];
    const filteredNonSpecFInstances = fittingInstances && fittingInstances.filter(item => item.doesMatchQuery(query, { normalized: false }));
    return removeDuplicateFromSpecFittingInstancesArray(denSPI.concat(filteredNonSpecFInstances.toArray())).map(item => convertToSpecFittingInstanceType(item));
  }
);
