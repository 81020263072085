import { toast } from 'react-toastify';

import EK from '../keys';

import api from '../../modules/api/actions';
import { REQUEST_TYPES } from '../../modules/api/constants';

import {
  fetchAllMapkeysTypes,
  createMapkeyTypes,
  editMapkeyTypes,
  removeMapkeyTypes,
} from './constants';
import schema from './schema';

const client = api(schema, EK.MAPKEYS);

export const processFetchAllMapkeys = () =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllMapkeysTypes,
    '/synchronize/mapkeys'
  );

export const processCreateMapkey = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    createMapkeyTypes,
    '/synchronize/mapkeys',
    {
      data: {
        mapkeys: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Mapkey successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create mapkey',
      },
    }
  );

export const processEditMapkey = (id, data) =>
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editMapkeyTypes,
    `/synchronize/mapkeys/${id}`,
    {
      data,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

export const processDeleteMapkey = (id) =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    removeMapkeyTypes,
    `/synchronize/mapkeys/${id}`,
    {
      deleteKey: EK.MAPKEYS.state,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );
