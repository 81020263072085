import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// primereact imports
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

// misc components
import Flex from '../../../components/common/Flex';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import ColorSwatch from '../../../components/common/ColorSwatch';
import DialogFooter from '../../../components/modal/ModalFooters/DialogFooter';

// selectors
import { createSelector } from 'reselect';
import { selectNormalizedAppearances } from '../../../entities/Appearances/selectors';
import { selectNormalizedInsulationSpecs } from '../../../entities/InsulationSpecs/selectors';

// actions
import { processFetchAllAppearances } from '../../../entities/Appearances/actions';
import { processFetchAllInsulationSpecs } from '../../../entities/InsulationSpecs/actions';

const mapStateToProps = createSelector(
  selectNormalizedAppearances(),
  selectNormalizedInsulationSpecs(),
  (appearances, insulationSpecs) => ({
    appearances: appearances?.toList().toArray() || [],
    insulationSpecs: insulationSpecs?.toList().toArray() || []
  })
);

export default function ({currentEditingRow, handleEditedRowChange, isDialogOpen=false, isCreateDialogOpen=false, isCopyDialogOpen=false, openDeleteDialog, saveAction, cancelDialogs, editable}) {
  const { appearances, insulationSpecs } = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const getDialogHeader = useMemo(() => {
    if (isCreateDialogOpen) {
      return 'Create Piping Spec';
    } else if (isCopyDialogOpen) {
      return `Create Copy of '${currentEditingRow.specName}'`;
    } else {
      return `Edit '${currentEditingRow.specName}'`;
    }
  }, [isDialogOpen]);

  useEffect(() => {
    dispatch(processFetchAllAppearances());
    dispatch(processFetchAllInsulationSpecs());
  }, []);

  return (
    <Dialog visible={editable && isDialogOpen} style={{ width: '75%' }} header={getDialogHeader} footer={() => DialogFooter(isCreateDialogOpen, isCopyDialogOpen, openDeleteDialog, saveAction, cancelDialogs)} modal className='p-fluid' closable={false} >
      <div>
        <label>Name</label>
        <InputText value={currentEditingRow.specName} onChange={(e) => handleEditedRowChange(e.target.value, 'specName')} />
      </div>
      <div>
        <label>Mnemonic</label>
        <InputText value={currentEditingRow.mnemonic} onChange={(e) => handleEditedRowChange(e.target.value, 'mnemonic')} />
      </div>
      <div>
        <label>Layer Name</label>
        <InputText value={currentEditingRow.layerName} onChange={(e) => handleEditedRowChange(e.target.value, 'layerName')} />
      </div>
      <div>
        <label>Appearance</label>
        <Dropdown
          optionLabel='name'
          value={currentEditingRow.appearance}
          options={appearances}
          onChange={(e) => handleEditedRowChange(e.target.value, 'appearance')}
          itemTemplate={(item) => <Flex flexDirection='row' ><ColorSwatch color={item.tertiary} /><ComplexCellRenderer value={{ primary: item.primary, secondary: item.secondary }} isCapitalized={false} placeholder='' /></Flex>}
          filter
        />
      </div>
      <div>
        <label>Default Insulation Spec</label>
        <Dropdown
          optionLabel='primary'
          optionValue='id'
          value={currentEditingRow.defaultInsulationSpec && currentEditingRow.defaultInsulationSpec.id ? currentEditingRow.defaultInsulationSpec.id : ''}
          options={insulationSpecs}
          onChange={(e) => handleEditedRowChange(insulationSpecs.find(item => item.id == e.target.value), 'defaultInsulationSpec')}
          itemTemplate={(item) => ComplexCellRenderer({
            value: { primary: item.primary, secondary: `${item.secondary ? item.secondary : '--'}` }, placeholder: '', isCapitalized: false
          })}
          filter
        />
      </div>
    </Dialog>
  );
}