import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';


import selectEntities from '../../modules/entities/selectors';
import EK from '../keys';
import ParameterProfileParameterSchema from './schema';
import { selectSidebarIsFiltering } from '../../routes/Dashboard/selectors';
import parameterSchema from './schema';
import { newParameterProfileParameterRows } from './constants';

export const selectNormalizedParameterProfileParameters = () =>
  createSelector(
    selectEntities(),
    (entities) => entities.get(EK.PARAMETER_PROFILE_PARAMETERS.state),
  );

export const selectParameterProfileParameters = () =>
  createSelector(
    selectEntities(),
    (entities) =>
      denormalize(entities.get(EK.PARAMETER_PROFILE_PARAMETERS.state), [
        ParameterProfileParameterSchema,
      ], entities.delete('parameterProfiles')),
  );


const pppFilterForm = formValueSelector(`filters.${EK.PARAMETER_PROFILE_PARAMETERS.state}`);

export const selectCurrentFilteredParameterProfileParameters = (ppId) => createSelector(
  selectNormalizedParameterProfileParameters(),
  selectSidebarIsFiltering(),
  state => pppFilterForm(state, 'name', 'valueType', 'description'),
  selectEntities(),
  (ppparameters, isFiltering, query, entities) => {
    let filteredParams = denormalize(ppparameters?.toList().filter(p => p.parameterProfileId == ppId) || List(), [parameterSchema], entities);
    return isFiltering ? filteredParams.filter(p => p.parameter.doesMatchQuery(query)).toArray() : filteredParams.toArray().concat(newParameterProfileParameterRows);
  }
);

export const selectParameterProfileCounts = (ppId) => createSelector(
  selectNormalizedParameterProfileParameters(),
  ppp => ppp?.filter(pp => pp.parameterProfileId == ppId)?.count() || 0
);