import React, {useState, useEffect} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import RowEndEntityTools from '../../../components/common/EntityTools/RowEndTools';

import Header from './Header';
import Options from '../../templates/Structures/Options';
import Flex from '../../../components/common/Flex/index';
import Text from '../../../components/common/Text';
import { createSelector } from 'reselect';

import {
  nameBody,
  dialogFooter,
  deleteDialogFooter,
  areFieldsValid,
  doRequiredFieldsExist
} from './components';

import {
  processFetchAllSimplifiedReps,
  processCreateSimplifiedRep,
  processCopySimplifiedRep,
  processEditSimplifiedRep,
  processDeleteSimplifiedRep,
} from '../../../entities/SimplifiedReps/actions';

import { showModal } from '../../../modules/modal/actions';
import { MODAL_SIZES } from '../../../containers/modal/Modal';

import { selectIsShowingArchived } from '../../../modules/query/selectors';
import { useDispatch, useSelector } from 'react-redux';

import { selectNormalizedSimplifiedRepsList } from '../../../entities/SimplifiedReps/selectors';

import {
  selectSidebarIsFiltering,
  selectStandardizeModulePermissionsAndState,
} from '../../Dashboard/selectors';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectNormalizedSimplifiedRepsList(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

const BLANK_REP = {
  name: '',
  description: '',
  creoAction: ''
};

const VALIDATION_FIELDS = {
  name: true,
  creoAction: true
};

export const DEFAULT_ACTION_OPTIONS = [
  { value: 'exclude', label: 'Exclude', primary: 'Exclude', id: 'exclude' },
  {
    value: 'master_rep',
    label: 'Master Rep',
    primary: 'Master Rep',
    id: 'master_rep',
  },
  {
    value: 'automatic_rep',
    label: 'Automatic Rep',
    primary: 'Automatic Rep',
    id: 'automatic_rep',
  },
  {
    value: 'default_envelope_rep',
    label: 'Default Envelope Rep',
    primary: 'Default Envelope Rep',
    id: 'default_envelope_rep',
  },
];

export default function (props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false); // controls if the create/edit/copy dialog is open
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false); // used to determine header text and select 'save' action
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false); // used to determine header text and select 'save' action
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false); // controls if the delete Dialog is open
  const [validation, setValidation] = useState(VALIDATION_FIELDS);

  const [currentEditingRow, setCurrentEditingRow] = useState(BLANK_REP);

  const getDialogHeader = () => {
    if (isCreateDialogOpen) {
      return 'Create Simplified Rep';
    } else if (isCopyDialogOpen) {
      return `Create Copy of '${currentEditingRow.name}'`;
    } else {
      return `Edit '${currentEditingRow.name}'`;
    }
  };

  const openCreateDialog = () => {
    setCurrentEditingRow(BLANK_REP);
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  const cancelDialogs = () => {
    // used to reset the dialogs
    setCurrentEditingRow(BLANK_REP);
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
    setIsDeleteDialogOpen(false);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setCurrentEditingRow(BLANK_REP);
  };

  const saveAction = () => {

    setValidation(areFieldsValid(currentEditingRow));
    if (!doRequiredFieldsExist(currentEditingRow)) {
      return;
    }

    const editedRow = {
      id: currentEditingRow.id,
      name: currentEditingRow.name,
      description: currentEditingRow.description,
      creoAction: currentEditingRow.creoAction
    };

    if (isCreateDialogOpen) {
      dispatch(processCreateSimplifiedRep(editedRow));
    } else if (isCopyDialogOpen) {
      dispatch(processCopySimplifiedRep(editedRow));
    } else {
      dispatch(processEditSimplifiedRep(editedRow.id, editedRow));
    }
    cancelDialogs();
  };

  const editRowAction = (rowdata) => {
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
      description: rowdata.description,
      creoAction: rowdata.creoAction,
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsDialogOpen(true);
  };

  const copyRowAction = (rowdata) => {
    const currentNonEntityRow = {
      id: rowdata.id,
      name: rowdata.name,
      description: rowdata.description,
      creoAction: rowdata.creoAction,
    };
    setCurrentEditingRow(currentNonEntityRow);
    setIsCopyDialogOpen(true);
    setIsDialogOpen(true);
  };

  const deleteAction = () => {
    // this action is PERMANENT, never call directly off of a button, always use the modal
    dispatch(processDeleteSimplifiedRep(currentEditingRow.id));
    setCurrentEditingRow(BLANK_REP);
    setIsDeleteDialogOpen(false);
  };

  const openDeleteDialog = () => {
    setIsDialogOpen(false);
    setIsCreateDialogOpen(false);
    setIsCopyDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  const handleEditedRowChange = (newValue, field) => {
    // called on every edit
    let _editedRow = { ...currentEditingRow };
    _editedRow[`${field}`] = newValue;
    setCurrentEditingRow(_editedRow);
  };

  const manageSimplifiedRep = (simplifiedRep) => {
    dispatch(
      showModal('SIMPLIFIED_REP_RULESET_MODAL', {
        modalSize: MODAL_SIZES.FLUID,
        simplifiedRepId: simplifiedRep,
      }),
    );
  };

  useEffect(() => {
    dispatch(processFetchAllSimplifiedReps());
  }, []);


  return (
    <>
      <Flex flexDirection="row" mb={4}>
        <Header/>
        <Options
          canFilter={false}
          isLoading={reduxProps.isLoading}
          createEntity={openCreateDialog}
          shouldHaveLicense={false}
          canCollaborate={reduxProps.canCollaborate}
        />
      </Flex>
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable value={reduxProps.data} tableStyle={{ minWidth: '50rem' }} size='normal' scrollable scrollHeight='flex' >
          <Column field='name' header="Name" style={{width: '20%'}} body={(rowData) => nameBody(rowData, manageSimplifiedRep)}></Column>
          <Column field='description' header='Description' style={{width: '20%'}}  ></Column>
          <Column field='creoAction' header='Default Action' style={{width: '20%'}}  ></Column>
          <Column header=''
            style={{textAlign: 'right', width: '20%'}}
            body={(rowData) =>
              <RowEndEntityTools
                rowdata={rowData}
                editAction={editRowAction}
                copyAction={copyRowAction}
                listAction={(data)=>manageSimplifiedRep(data.id)}
              />}
          ></Column>
        </DataTable>
      </Flex>
      <Dialog visible={reduxProps.editable && isDialogOpen} style={{ width: '75%' }} header={()=>getDialogHeader()} footer={() => dialogFooter(isCreateDialogOpen, isCopyDialogOpen, openDeleteDialog, saveAction, cancelDialogs)} modal className='p-fluid' closable={false} >
        <div>
          <label>Name</label>
          <InputText value={currentEditingRow.name} onChange={(e) => handleEditedRowChange(e.target.value, 'name')} className={!validation.name && 'p-invalid'} />
          <label>Description</label>
          <InputText value={currentEditingRow.description} onChange={(e) => handleEditedRowChange(e.target.value, 'description')} />
          <label>Default Action</label>
          <Dropdown value={currentEditingRow.creoAction} options={DEFAULT_ACTION_OPTIONS} optionLabel='label' optionValue='id' onChange={(e) => handleEditedRowChange(e.target.value, 'creoAction')} className={!validation.creoAction && 'p-invalid'} />
        </div>
      </Dialog>
      <Dialog visible={reduxProps.editable && isDeleteDialogOpen} style={{ width: '32rem' }} header={`Delete Simplified Rep '${currentEditingRow.name}'`} footer={() => deleteDialogFooter(closeDeleteDialog, deleteAction)} closable={false} >
        <div>
          <Text>Are you sure you want to delete {currentEditingRow.name}?</Text>
          <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
          <Text><strong>Only delete this if you are certain that it needs to be removed from everywhere</strong></Text>
        </div>
      </Dialog>
    </>
  );

}

