import EK from '../keys';

import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = EK.BENDS.state;

export const fetchAllBendFamiliesForPipeFamilyTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const fetchIndividualBendFamilyForPipeFamilyTypes = generateAPHTypes(resource, 'FETCH');

export const createBendFamilyForPipeFamilyTypes = generateAPHTypes(resource, 'CREATE');

export const copyBendFamilyForPipeFamilyTypes = generateAPHTypes(resource, 'COPY');

export const editBendFamilyForPipeFamilyTypes = generateAPHTypes(resource, 'EDIT');

export const deleteBendFamilyForPipeFamilyTypes = generateAPHTypes(resource, 'DELETE');

export const archiveBendFamiliesForPipeFamilyTypes = generateAPHTypes(resource, 'ARCHIVE');

export const unarchiveBendFamiliesForPipeFamilyTypes = generateAPHTypes(resource, 'UNARCHIVE');
