import baseSchema from '../../modules/entities/utils/schema';

import EK from '../keys';

import NoteSchema from '../Notes/schema';

const noteProfileSchema = baseSchema(EK.NOTE_PROFILES.state, {
  noteBlocks: { isId: true, idField: 'noteBlockIds', isList: true },
});

export const noteBlockSchema = baseSchema(EK.NOTE_BLOCKS.state, {
  noteProfile: { schema: noteProfileSchema, isId: true },
  noteBlockNotes: { isId: true, idField: 'noteBlockNoteIds', isList: true },
});

export const noteBlockNoteSchema = baseSchema(EK.NOTE_BLOCK_NOTES.state, {
  noteBlock: { schema: noteBlockSchema, isId: true },
  note: { schema: NoteSchema, isId: true },
});

noteBlockSchema.define({ noteBlockNotes: [noteBlockNoteSchema] });

noteProfileSchema.define({ noteBlocks: [noteBlockSchema] });

export default noteProfileSchema;
