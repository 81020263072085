import React from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Notice from '../../../containers/common/Notice';

import { selectIsFetching } from '../../../modules/utility/selectors';

import { selectCurrentNormalizedSpecFittingInstances } from '../../../entities/SpecFittingInstances/selectors';

const mapStateToProps = (specId) => createSelector(
  selectIsFetching(),
  selectCurrentNormalizedSpecFittingInstances(specId),
  (isFetching, data) => ({ isFetching, data })
);

const SpecsFittingInstancesNotices = ({ match }) => {
  const { isFetching, data } = useSelector(mapStateToProps(match.params.id));

  return isFetching && data && data.size > 0 && <Notice id='specsFittingInstances' message='Loading data in the background...' /> || null;
};

export default withRouter(SpecsFittingInstancesNotices);
