import React from 'react';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';
import { FrameworkModuleProfileLibrariesPath } from '../../../paths';
import { useSelector } from 'react-redux';

import { selectCurrentProfileLibraryName } from '../../../entities/ProfileLibraries/selectors';

export default function () {
  return (
    <BreadcrumbTemplate title={FrameworkModuleProfileLibrariesPath.defaultTitle} ></BreadcrumbTemplate>
  );
}

export function BaseLibraryBreadcrumb () {
  const library = useSelector(selectCurrentProfileLibraryName());
  return (
    <BreadcrumbTemplate title={library || ''} ></BreadcrumbTemplate>
  );
}