import EK from '../keys';

import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = EK.INSULATIONS.state;

export const fetchAllInsulationFamiliesTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const fetchIndividualInsulationFamilyTypes = generateAPHTypes(resource, 'FETCH');

export const createInsulationFamilyTypes = generateAPHTypes(resource, 'CREATE');

export const copyInsulationFamilyTypes = generateAPHTypes(resource, 'COPY');

export const editInsulationFamilyTypes = generateAPHTypes(resource, 'EDIT');

export const deleteInsulationFamilyTypes = generateAPHTypes(resource, 'DELETE');

export const archiveInsulationFamiliesTypes = generateAPHTypes(resource, 'ARCHIVE');

export const unarchiveInsulationFamiliesTypes = generateAPHTypes(resource, 'UNARCHIVE');
