import { put, fork, select } from 'redux-saga/effects';

import { hideModal } from '../../../../modules/modal/actions';

import { selectCurrentUser, selectCurrentOrganization } from '../../../../entities/CurrentUser/selectors';

import { processFetchAllUsers } from '../../../../entities/Users/actions';
import { processFetchAllOrganizationUserInvites } from '../../../../entities/OrganizationUserInvites/actions';
import { processFetchMasterCatalog } from '../../../../entities/PipingCatalogs/actions';

export function* watchLoadOrganizationDirectorySaga() {
  try {
    const currentUser = yield select(selectCurrentUser());
    const currentOrganization = yield select(selectCurrentOrganization());

    yield put(processFetchAllUsers(currentOrganization.id));

    if (currentUser && currentUser.isAdmin) {
      yield put(processFetchAllOrganizationUserInvites(currentOrganization.id));
    }

    if (currentOrganization.hasModulePipingPrivileges) {
      yield put(processFetchMasterCatalog());
    }
  } catch (error) {
    yield put(hideModal());
  }
}

// final output saga
export default function* main() {
  yield fork(watchLoadOrganizationDirectorySaga);
}