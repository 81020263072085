import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';
import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';
import { selectSidebarIsFiltering } from '../../routes/Dashboard/selectors';

import NoteSchema from './schema';

export const selectNormalizedNotes = () =>  createSelector(
  selectEntities(),
  entities => entities.get(EK.NOTES.state)
);
export const selectNormalizedNotesList = () => createSelector(
  selectNormalizedNotes(),
  notes => notes.toList()
);

export const selectNotes = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.NOTES.state), [NoteSchema], entities)
);

export const selectNotesCount = () => createSelector(
  selectNormalizedNotes(),
  notes => notes.toList().count()
);

const noteFilterForm = formValueSelector(`filters.${EK.NOTES.state}`);

export const selectCurrentFilteredNormalizedDraftNotes = () => createSelector(
  selectSidebarIsFiltering(),
  selectNormalizedNotes(),
  state => noteFilterForm(state, 'name', 'noteFeatureName', 'noteBody', 'description'),
  (isFiltering, notes, query) => isFiltering ? notes.filter(note => note.doesMatchQuery(query)) : notes
);

export const selectCurrentFilteredNotes = () => createSelector(
  selectCurrentFilteredNormalizedDraftNotes(),
  selectEntities(),
  (notes, entities) => denormalize(notes, [NoteSchema], entities)
);