import EK from '../keys';

import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = EK.MAPKEYS.state;

export const fetchAllMapkeysTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createMapkeyTypes = generateAPHTypes(resource, 'CREATE');

export const editMapkeyTypes = generateAPHTypes(resource, 'EDIT');

export const removeMapkeyTypes = generateAPHTypes(resource, 'REMOVE');
