import EK from '../keys';

import baseSchema from '../../modules/entities/utils/schema';

import profileSchema from '../Profiles/schema';

const dimensionSchema = baseSchema(EK.PROFILE_PARAMETERS.state, {
  profileId: { schema: profileSchema, isId: true}
});

export default dimensionSchema;
