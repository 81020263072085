import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Field, reduxForm } from 'redux-form/immutable';
import times from 'lodash/times';

import EK from '../../../entities/keys';

import BaseFormRow from '../../../components/form/BaseFormRow';

import FilterFormTemplate from '../../templates/Structures/FilterFormTemplate';

import RDXTextInput from '../../../containers/form/RDXTextInput';
import RDXSearchSelect from '../../../containers/form/RDXSearchSelect';

import {
  selectPortNames,
} from '../../../entities/FittingInstances/selectors';

import { selectCurrentFittingCode } from '../../../entities/FittingFamilies/selectors';

const FilterForm = ({ portNames, endPortCount, ...props }) => (
  <FilterFormTemplate {...props} overflow={true} >
    <BaseFormRow>
      <Field
        component={RDXSearchSelect}
        name='schedule'
        label='Schedule'
        ek={EK.SCHEDULES}
        clearable
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='cadModelName'
        label='CAD Model Name'
        type='text'
      />
    </BaseFormRow>
    {
      times(endPortCount, idx => (
        <React.Fragment>
          <BaseFormRow>
            <Field
              component={RDXSearchSelect}
              name={`port${idx}Size`}
              label={`${portNames.get(idx)} Size`}
              ek={EK.SIZES}
              clearable
            />
          </BaseFormRow>
          <BaseFormRow>
            <Field
              component={RDXSearchSelect}
              name={`port${idx}EndType`}
              label={`${portNames.get(idx)} End Type`}
              ek={EK.END_TYPES}
              clearable
            />
          </BaseFormRow>
        </React.Fragment>
      ))
    }
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='stockno'
        label='Stock no'
        type='text'
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='mccsCode'
        label='MCCS Code'
        type='text'
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXSearchSelect}
        name='boltNutFamily'
        label='Bolt Nut'
        ek={EK.BOLT_NUTS}
        clearable
      />
    </BaseFormRow>
  </FilterFormTemplate>
);

const mapStateToProps = createSelector(
  selectPortNames(),
  selectCurrentFittingCode(),
  (portNames, fittingCode) => {
    return {
      portNames,
      endPortCount: fittingCode && fittingCode.endCount || 1,
    };
  }
);

const enhance = compose(
  reduxForm({ form: `filters.${EK.SPEC_FITTING_INSTANCES.state}` }),
  connect(mapStateToProps),
);

export default enhance(FilterForm);
