import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = 'PROFILES';

export const fetchAllProfileTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createProfileTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const copyProfileTypes = generateAPHTypes(
  resource,
  'COPY'
);

export const editProfileTypes = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteProfileTypes = generateAPHTypes(
  resource,
  'DELETE'
);