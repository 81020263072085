import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = 'PROFILE_TABLES';

export const fetchAllTableTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createTableTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const copyTableTypes = generateAPHTypes(
  resource,
  'COPY'
);

export const editTableTypes = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteTableTypes = generateAPHTypes(
  resource,
  'DELETE'
);