import EK from '../keys';

import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = EK.PROJECTS.state;

export const fetchAllProjectsTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const fetchIndividualProjectTypes = generateAPHTypes(resource, 'FETCH');

export const createProjectTypes = generateAPHTypes(resource, 'CREATE');

export const copyProjectTypes = generateAPHTypes(resource, 'COPY');

export const editProjectTypes = generateAPHTypes(resource, 'EDIT');

export const archiveProjectsTypes = generateAPHTypes(resource, 'ARCHIVE');

export const unarchiveProjectsTypes = generateAPHTypes(resource, 'UNARCHIVE');

export const saveIndividualProjectSpecsTypes = generateAPHTypes(resource, 'SAVE_SPECS');

export const deleteProjectTypes = generateAPHTypes(resource, 'DELETE');