import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = 'PROFILE_TABLE_ITEMS';

export const fetchAllTableItemTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createTableItemTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const copyTableItemTypes = generateAPHTypes(
  resource,
  'COPY'
);

export const editTableItemTypes = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteTableItemTypes = generateAPHTypes(
  resource,
  'DELETE'
);