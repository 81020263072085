import React from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';
import { useSelector } from 'react-redux';

import {
  ParameterProfilesEditPath,
} from '../../../paths';
import { selectCurrentParameterProfile } from '../../../entities/ParameterProfiles/selectors';

function ProjEditBreadcrumb({ location }) {
  const match = matchPath(location.pathname, { path: ParameterProfilesEditPath.url });
  const parameterId = (match && match.params.id) || null;
  const parameter = useSelector(selectCurrentParameterProfile(parameterId));

  return <BreadcrumbTemplate title={(parameter && parameter.name) || ParameterProfilesEditPath.defaultTitle} />;
}

export default withRouter(ProjEditBreadcrumb);