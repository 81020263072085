import { toast } from 'react-toastify';

import EK from '../keys';

import pipeClient from './client';

import { REQUEST_TYPES } from '../../modules/api/constants';

import pipeFamilyKeys from './keys';
import pipeInstanceKeys from '../PipeInstances/keys';

import {
  fetchAllPipeFamiliesTypes,
  fetchIndividualPipeFamilyTypes,
  createPipeFamilyTypes,
  copyPipeFamilyTypes,
  editPipeFamilyTypes,
  deletePipeFamilyTypes,
  archivePipeFamiliesTypes,
  unarchivePipeFamiliesTypes,
} from './constants';

export const processFetchAllPipeFamilies = () => pipeClient(REQUEST_TYPES.GET, 'plural', fetchAllPipeFamiliesTypes, '/piping/pipe_families?all=true');

export const processFetchPipeFamiliesForSpec = () => pipeClient(REQUEST_TYPES.GET, 'plural', fetchAllPipeFamiliesTypes, '/piping/pipe_families?all=true&with_full_detail=true',
  {
    mutateResponse: ({pipeFamilies}) => ({
      pipeFamilies: pipeFamilies.map(({ id, pipeInstances = [], ...other } = {}) => ({
        ...other,
        id,
        pipeInstances: pipeInstances.map(instance => ({
          ...instance,
          pipeFamily: `${id}`,
          pipeFamilyId: `${id}`,
        })),
      }))
    })
  }
);

export const processFetchIndividualPipeFamily = pipeId => pipeClient(REQUEST_TYPES.GET, 'single', fetchIndividualPipeFamilyTypes, `/piping/pipe_families/${pipeId}?with_full_detail=true`, {
  mutateResponse: ({ pipeFamily: { id, pipeInstances = [], ...other } = {} }) => ({
    pipeFamily: {
      ...other,
      id,
      pipeInstances: pipeInstances.map(instance => ({
        ...instance,
        pipeFamily: `${id}`,
        pipeFamilyId: `${id}`,
      })),
    },
  }),
});

export const processCreatePipeFamily = data => pipeClient(REQUEST_TYPES.POST, 'plural', createPipeFamilyTypes, '/piping/pipe_families', {
  data: {
    pipeFamilies: [data],
  },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Pipe successfully created',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to create pipe',
  },
});

export const processCopyPipeFamily = (data) =>
  pipeClient(
    REQUEST_TYPES.POST,
    'plural',
    copyPipeFamilyTypes,
    '/piping/pipe_families/copy',
    {
      data: {
        [EK.PIPES.plural]: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Pipe successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy pipe',
      },
    }
  );

export const processEditPipeFamily = (pipeId, data) => pipeClient(REQUEST_TYPES.PATCH, 'single', editPipeFamilyTypes, `/piping/pipe_families/${pipeId}`, {
  data,
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes!!!',
  },
});

export const processDeletePipeFamily = pipeId => pipeClient(REQUEST_TYPES.DELETE, 'single', deletePipeFamilyTypes, `/piping/pipe_families/${pipeId}?with_full_detail=true`, {
  deleteKey: pipeFamilyKeys.state,
  dependentDeletionKeys: [pipeInstanceKeys.state],
  mutateResponse: ({ pipeFamily: { id, ...other } = {}, pipeInstances = [] }) => ({
    pipeFamily: {
      ...other,
      id,
      pipeInstances: pipeInstances.map(instance => ({
        ...instance,
        pipeFamily: `${id}`,
        pipeFamilyId: `${id}`,
      })),
    },
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Pipe deleted',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to delete pipe',
  },
});

export const processArchivePipeFamilies = pipeIds => pipeClient(REQUEST_TYPES.ARCHIVE, 'plural', archivePipeFamiliesTypes, '/piping/pipe_families/archive', {
  data: { ids: pipeIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Pipe archived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to archive pipe',
  },
});

export const processUnarchivePipeFamilies = pipeIds => pipeClient(REQUEST_TYPES.UNARCHIVE, 'plural', unarchivePipeFamiliesTypes, '/piping/pipe_families/unarchive', {
  data: { ids: pipeIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Pipe unarchived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to unarchive pipe',
  },
});