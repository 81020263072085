import { ParametersType } from '../../../entities/Parameters/types';
import { convertListToString } from '../../../entities/Parameters/model';

// type conversion
export const convertToParametersType = ({
  id,
  name,
  valueType,
  defaultValue,
  listValues,
  unit,
  description,
}: ParametersType) => ({
  id,
  name,
  valueType,
  defaultValue,
  listValues,
  unit,
  description,
});

// field validation for dialog
export function areFieldsValid({
  name,
  valueType,
}: ParametersType) {
  // there is technically more complex logic here for verifying based on the valueType
  const _VALIDATION_FIELDS = {
    name: !!name,
    valueType: !!valueType,
  };
  return _VALIDATION_FIELDS;
}

export function doRequiredFieldsExist({ name, valueType }: any) {
  // takws the VALIDATION_FIELDS type, so all values are booleans
  return !!name && !!valueType;
}
