import EntityRecord, { FILTERS } from '../../modules/entities/model';

// prettier-ignore
export default class ProfileTableRow extends EntityRecord({
  selectionString: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  table: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: false,
  }
}) {}
