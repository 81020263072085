import { toast } from 'react-toastify';

import EK from '../keys';

import api from '../../modules/api/actions';
import { REQUEST_TYPES } from '../../modules/api/constants';

import {
  fetchAllAppearanceStateTypes,
  createAppearanceStateTypes,
  copyAppearanceStateTypes,
  editAppearanceStateTypes,
  deleteAppearanceStateTypes
} from './constants';
import schema from './schema';

const client = api(schema, EK.APPEARANCE_STATES);

export const processFetchAllAppearanceStates = () =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllAppearanceStateTypes,
    '/standardize/appearance_states'
  );

export const processCreateAppearanceState = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    createAppearanceStateTypes,
    '/standardize/appearance_states',
    {
      data: {
        appearanceStates: [{
          name: data.name,
          description: data.description
        }],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Appearance State successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create Appearance State',
      },
    }
  );

export const processEditAppearanceState = (AppearanceStateId, data) =>
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editAppearanceStateTypes,
    `/standardize/appearance_states/${AppearanceStateId}`,
    {
      data,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

export const processDeleteAppearanceState = (AppearanceStateId) =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteAppearanceStateTypes,
    `/standardize/appearance_states/${AppearanceStateId}?with_full_detail=true`,
    {
      deleteKey: EK.APPEARANCE_STATES.state,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Appearance State deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete Appearance State',
      },
    }
  );


//not sure this is necessary, not going to test it or add it until James approves
export const processCopyAppearanceState = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copyAppearanceStateTypes,
    '/standardize/appearance_states/copy',
    {
      data: {
        appearanceStates: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Appearance State successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy Appearance State',
      },
    }
  );