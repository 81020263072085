import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

import MapkeySchema from './schema';

export const selectNormalizedMapkeys = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get(EK.MAPKEYS.state)
  );
export const selectNormalizedMapkeysList = () =>
  createSelector(selectNormalizedMapkeys(), (mapkeys) =>
    mapkeys.toList().toArray()
  );

export const selectNormalizedDraftMapkeys = () =>
  createSelector(selectDrafts(), (drafts) => {
    const state = drafts.get(EK.MAPKEYS.state);
    const records = state.get('records');
    return state.get('ids').map((id) => records.get(id));
  });

export const selectMapkeys = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.MAPKEYS.state),
      [MapkeySchema],
      entities
    )
  );
export const selectDraftMapkeys = () =>
  createSelector(
    selectEntities(),
    selectNormalizedDraftMapkeys(),
    (entities, drafts) =>
      denormalize(drafts, [MapkeySchema], entities)
  );
