import { createSelector } from 'reselect';

import selectEntities from '../../modules/entities/selectors';

export const selectNormalizedFittingCodes = () => createSelector(
  selectEntities(),
  entities => entities.get('fittingCodes')
);
export const selectFittingCodesList = () => createSelector(
  selectNormalizedFittingCodes(),
  (fittingCodes) => fittingCodes.toList()
);
export const selectFittingCodesOptions = () => createSelector(
  selectFittingCodesList(),
  (fittingCodes) => fittingCodes.map(fittingCode => fittingCode.toOption())
);

export const selectBranchLetFittingCode = () => createSelector(
  selectNormalizedFittingCodes(),
  fittingCodes => fittingCodes.find(fittingCode => fittingCode.name === 'BRANCH_LET')
);
