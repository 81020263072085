import { createSelector } from 'reselect';

import { denormalize } from 'normalizr';

import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';

import ParameterSchema from './schema';

export const selectNormalizedParameterProfiles = () =>  createSelector(
  selectEntities(),
  entities => entities.get('parameterProfiles')
);
export const selectNormalizedParameterProfilesList = () => createSelector(
  selectNormalizedParameterProfiles(),
  parameterProfiles => parameterProfiles.toList()
);
export const selectParameterProfiles = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.PARAMETER_PROFILES.state),
      [ParameterSchema],
      entities
    )
  );

export const selectCurrentParameterProfile = (ppId) => createSelector(
  selectEntities(),
  (entities) => entities.hasIn([EK.PARAMETER_PROFILES.state, ppId]) && denormalize(entities.getIn([EK.PARAMETER_PROFILES.state, ppId]), ParameterSchema, entities)
);
