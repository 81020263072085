import baseSchema from '../../modules/entities/utils/schema';

import EK from '../keys';

import SimplifiedRepRulesetSchema from '../SimplifiedRepRulesets/schema';

// prettier-ignore
const parameterSimplifiedRepSchema = baseSchema(
  EK.SIMPLIFIED_REPS.state,
  {
    simplifiedRepRulesets: {
      schema: [SimplifiedRepRulesetSchema],
      isId: true,
      idField: 'simplifiedRepRulesetIds',
      isList: true,
    },
  }
);

export default parameterSimplifiedRepSchema;
