import EntityRecord, { VALIDATORS, FILTERS } from '../../modules/entities/model';

export const ALLOWANCES = {
  NOTE_FEATURE_NAME: /^([a-zA-Z0-9_]{0,32})$/,
};

class NoteBlockNote extends EntityRecord({
  noteBlock: {
    value: null,
    isEntity: true,
    handleCopy: true,
  },
  noteBlockId: {
    value: null,
    handleCopy: true,
  },
  note: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'noteId',
  },
  sortOrder: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
  },
  prefix: {
    value: null,
    handleCompare: true,
    handleSerialize: (entity) => entity.prefix || null,
  },
  isOptional: {
    value: false,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.BOOLEAN
  },
  isFlagNote: {
    value: false,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.BOOLEAN
  }
}) {
  // get primary() { return this.name; }
  // get primaryPlaceholder() { return 'No Name'; }
  // get secondary() { return this.noteFeatureName; }
  // get secondaryPlaceholder() { return '--'; }
}

export default NoteBlockNote;
