import { List } from 'immutable';

import EntityRecord, { VALIDATORS, FILTERS } from '../../modules/entities/model';

export const ALLOWANCES = {
  NAME: /^(.{0,255})$/,
};

export const VALIDATION_FIELDS = {
  name: true,
  noteBody: true,
};

export const NOTE_TYPES = {
  GENERAL: 'general_note',
  COMPONENT: 'component_note',
  ECAD_AREA: 'ecad_area_note',
};

export const NOTE_TYPES_OPTIONS = [
  {
    id: NOTE_TYPES.GENERAL,
    value: NOTE_TYPES.GENERAL,
    primary: 'General Note',
    label: 'General Note',
  },
  {
    id: NOTE_TYPES.COMPONENT,
    value: NOTE_TYPES.COMPONENT,
    primary: 'Component Note',
    label: 'Component Note',
  },
  {
    id: NOTE_TYPES.ECAD_AREA,
    value: NOTE_TYPES.ECAD_AREA,
    primary: 'Ecad Area Note',
    label: 'Ecad Area Note',
  },
];

class Note extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      VALIDATORS.IS_REQUIRED_STRING(entity.name) &&
      ALLOWANCES.NAME.test(entity.name),
    validateError: 'Name must be present and at most 255 characters in length',
    filter: FILTERS.STRING,
  },
  noteBody: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) => VALIDATORS.IS_REQUIRED_ENTITY(entity.noteBody),
    validateError: 'Note body must be present',
    filter: FILTERS.STRING,
  },
  noteType: {
    value: NOTE_TYPES.GENERAL,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  description: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
}) {
  get primary() {
    return this.name;
  }
  get primaryPlaceholder() {
    return 'No Name';
  }
  get secondary() {
    return this.description;
  }
  get secondaryPlaceholder() {
    return 'No Description';
  }
}

export default Note;
