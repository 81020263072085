import EntityRecord, { VALIDATORS } from '../../modules/entities/model';

class EndTypeCompatibility extends EntityRecord({
  firstEndType: {
    value: null,
    isEntity: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'firstEndTypeId',
    validate: entity => VALIDATORS.IS_REQUIRED_ENTITY(entity.firstEndType),
    validateError: 'A valid end type must be present',
  },
  secondEndType: {
    value: null,
    isEntity: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'secondEndTypeId',
    validate: entity => VALIDATORS.IS_REQUIRED_ENTITY(entity.secondEndType),
    validateError: 'A valid end type must be present',
  },
  firstSchedule: {
    value: null,
    isEntity: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'firstScheduleId',
  },
  secondSchedule: {
    value: null,
    isEntity: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'secondScheduleId',
  },
}) {}

export default EndTypeCompatibility;
