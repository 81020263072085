import EK from '../keys';

import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = EK.MITERS.state;

export const fetchAllMiterFamiliesForPipeFamilyTypes = generateAPHTypes(resource, 'FETCH_ALL');

export const fetchIndividualMiterFamilyForPipeFamilyTypes = generateAPHTypes(resource, 'FETCH');

export const createMiterFamilyForPipeFamilyTypes = generateAPHTypes(resource, 'CREATE');

export const copyMiterFamilyForPipeFamilyTypes = generateAPHTypes(resource, 'COPY');

export const editMiterFamilyForPipeFamilyTypes = generateAPHTypes(resource, 'EDIT');

export const deleteMiterFamilyForPipeFamilyTypes = generateAPHTypes(resource, 'DELETE');

export const archiveMiterFamiliesForPipeFamilyTypes = generateAPHTypes(resource, 'ARCHIVE');

export const unarchiveMiterFamiliesForPipeFamilyTypes = generateAPHTypes(resource, 'UNARCHIVE');
