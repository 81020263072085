import EK from '../keys';

import baseSchema from '../../modules/entities/utils/schema';

import ConfigSettingSchema from '../ConfigSettings/schema';
import MapkeySchema from '../Mapkeys/schema';

const configProfileValueSchema = baseSchema(EK.CONFIG_PROFILE_VALUES.state, {
  [EK.CONFIG_SETTINGS.single]: { schema: ConfigSettingSchema, isId: true },
});

const configProfileMapkeySchema = baseSchema(EK.CONFIG_PROFILE_MAPKEYS.state, {
  mapkey: { schema: MapkeySchema, isId: true },
});

const configProfileSchema = baseSchema(EK.CONFIG_PROFILES.state, {
  [EK.CONFIG_PROFILE_VALUES.plural]: { schema: [configProfileValueSchema], isList: true },
  [EK.CONFIG_PROFILE_MAPKEYS.plural]: { schema: [configProfileMapkeySchema], isList: true },
});

configProfileValueSchema.define({ [EK.CONFIG_PROFILES.single]: configProfileSchema });
configProfileMapkeySchema.define({ [EK.CONFIG_PROFILES.single]: configProfileSchema });

export {
  configProfileValueSchema,
  configProfileMapkeySchema,
};

export default configProfileSchema;
