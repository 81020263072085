import EntityRecord, { VALIDATORS, FILTERS } from '../../modules/entities/model';

class EndType extends EntityRecord({
  name: {
    value: null,
    handleCopy: entity => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    validate: entity => VALIDATORS.IS_REQUIRED_STRING(entity.name),
    validateError: 'A valid name must be present',
    filter: FILTERS.STRING,
  },
  code: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: entity => VALIDATORS.IS_REQUIRED_STRING(entity.code),
    validateError: 'A valid code must be present',
    filter: FILTERS.STRING,
  },
}) {
  get primary() { return this.name; }
  get primaryPlaceholder() { return 'No Name'; }
  get secondary() { return this.code; }
  get secondaryPlaceholder() { return '--'; }
}

export default EndType;
