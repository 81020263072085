import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Field,
  reduxForm,
  clearFields,
} from 'redux-form/immutable';
import { List } from 'immutable';
import styled from 'styled-components';
import space from '../../../../assets/themes/base/space';
import borders from '../../../../assets/themes/base/borders';
import fontSizes from '../../../../assets/themes/base/fontSizes';
import colors from '../../../../assets/themes/base/colors';
import radii from '../../../../assets/themes/base/radii';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { createSelector } from 'reselect';

import EK from '../../../../entities/keys';

import useFloatingState from '../../../../hooks/useFloatingState';
import useIsHovering from '../../../../hooks/useIsHovering';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';
import Flex from '../../../../components/common/Flex';
import Label from '../../../../components/common/Label';
import Text from '../../../../components/common/Text';
import VariableItemSizeList from '../../../../components/common/VariableItemSizeList';

import Tooltip, { CONTAINERS, LAYER_PRIORITY } from '../../../../components/tooltip/Tooltip';
import TooltipBody from '../../../../components/tooltip/TooltipBody';
import TooltipSection from '../../../../components/tooltip/TooltipSection';

import RDXCheckboxInput from '../../RDXCheckboxInput';

import { selectNormalizedConfigProfileMapkeys } from '../../../../entities/ConfigProfileMapkeys/selectors';

import { processFetchAllMapkeys } from '../../../../entities/Mapkeys/actions';
import { selectNormalizedMapkeys } from '../../../../entities/Mapkeys/selectors';

const Row = styled(Flex)`
  flex-direction: row;
  padding-left: ${space[3]};
  padding-right: ${space[3]};
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;

const MapkeyField = styled(Box)`
  height: 4.75rem;
  width: 5rem;
  // border: ${borders[2]};
  border-radius: ${radii[2]};
  overflow: hidden;
`;

const MapkeyDetails = styled(Flex)`
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  flex: auto;
`;

const MapkeyName = styled(Text)`
  flex: 1;
  font-size: ${fontSizes[2]};
  font-weight: 500;
  margin-bottom: ${space[1]};
  text-align: left;
`;

const MapkeyKeystroke = styled(Box)`
  flex: 1;
  margin-bottom: ${space[1]};
  text-align: center;
`;

const MapkeyKeystrokeLabel = styled(Label)`
  font-family: monospace;
`;

const MapkeyDescription = styled(Text)`
  flex: 1;
  font-size: ${fontSizes[1]};
  color: ${colors.gray[6]};
  text-align: left;
`;

const StyledField = styled(Field)`
  align-items: center
`;

const generateRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

const SkeletonMapkeyFieldRow = ({ height }) => {
  const randomFieldType = generateRandomNumber(1, 3);
  return (
    <Flex flexDirection='column' height={height}>
      <Flex flexDirection='row' p={4}>
        <Flex flexDirection='row' alignItems='center' width='7rem' justifyContent='space-between' mr={4} />
        <MapkeyDetails>
          <MapkeyKeystroke>
            <MapkeyKeystrokeLabel secondary>
              <Skeleton width={`${generateRandomNumber(4, 7)}rem`} />
            </MapkeyKeystrokeLabel>
          </MapkeyKeystroke>
          <MapkeyName color='gray.7'>
            <Skeleton width={`${generateRandomNumber(30, 80)}%`} />
          </MapkeyName>
          <MapkeyDescription>
            <Skeleton count={2} />
          </MapkeyDescription>
        </MapkeyDetails>
        <Flex flexDirection='column' justifyContent='center' width='40%' mr={4}>
          <MapkeyField>
            {
              randomFieldType === 1 && <Skeleton height='4rem' />
            }
            {
              randomFieldType === 2 && <Skeleton height='1.25rem' width='1.25rem' />
            }
            {
              randomFieldType === 3 && <Skeleton height='2rem' width='4rem' />
            }
          </MapkeyField>
        </Flex>
      </Flex>
    </Flex>
  );
};

const MapkeyFormField = ({ mapkey, name, isPerforming }) => (
  <MapkeyField>
    <StyledField
      component={RDXCheckboxInput}
      name={name || mapkey.id}
      disabled={isPerforming}
      defaultValue={false}
    />
  </MapkeyField>
);

const createSelectMapkeyForForm = () => createSelector(
  selectNormalizedMapkeys(),
  (_, mapkeyId) => mapkeyId,
  (mapkeys, mapkeyId) => mapkeys.get(mapkeyId)
);

const MapkeyFieldItem = ({  index, id, initialValue, isPerforming, isVisible, setSize  }) => {
  const rowRoot = useRef();

  // pretty sure these two useMemos do nothing
  // TODO: test to confirm in later cleanup PR
  const selectMapkeyForForm = useMemo(createSelectMapkeyForForm, []);
  const selectMapkeysInProfile = useMemo(selectNormalizedConfigProfileMapkeys, []);
  const mapkey = useSelector(state => selectMapkeyForForm(state, id));
  const mapkeysInProfile = useSelector(state => selectMapkeysInProfile(state, id));

  // TODO: this is broken; compare with ConfigProfileSettingsForm
  // something to do with drafts / submitting the form state?
  const isEdited = useMemo(() => {
    const maybeProfileMapkey = mapkeysInProfile.find(
      (profileMapkey) => profileMapkey.get(EK.MAPKEYS.single) === mapkey.id
    );
    return initialValue !== !!maybeProfileMapkey;
  }, [mapkey, mapkeysInProfile]);

  const dispatch = useDispatch();
  const undoFormValueChange = useCallback(() => {
    dispatch(clearFields(EK.CONFIG_PROFILE_MAPKEYS.state, true, true, mapkey.id));
  }, [dispatch, mapkey]);

  const [undoButtonReference, undoButtonFloating, undoButtonFloatingStyle] = useFloatingState({
    placement: 'bottom'
  });
  const isHoveringUndoButton = useIsHovering(undoButtonReference, { delayEnter: 500 });

  useEffect(() => {
    if (rowRoot.current) {
      setSize && setSize(index, rowRoot.current.getBoundingClientRect().height);
    }
  }, [index, setSize]);

  return (
    <Row ref={rowRoot} p={4} bg={isEdited && 'primary.0' || undefined}>
      <MapkeyDetails>
        {
          isEdited &&
          <Button
            ref={el => undoButtonReference.current = el}
            transparent
            subtle
            large
            primary
            icon='history'
            onClick={undoFormValueChange}
            type='button'
          /> || null
        }
        {
          isHoveringUndoButton && ReactDOM.createPortal(
            <Tooltip ref={undoButtonFloating} style={undoButtonFloatingStyle} size='small' priority={LAYER_PRIORITY.MODAL_DROPDOWN}>
              <TooltipBody>
                <TooltipSection small inverse>Undo any unsaved changes and reset the value to the last saved value</TooltipSection>
              </TooltipBody>
            </Tooltip>,
            document.querySelector(CONTAINERS.TOOLTIP)
          )
        }
        <MapkeyKeystroke>
          <MapkeyKeystrokeLabel secondary>
            { mapkey.keystroke }
          </MapkeyKeystrokeLabel>
        </MapkeyKeystroke>
        <MapkeyName color='gray.7'>
          { mapkey.name }
        </MapkeyName>
        <MapkeyDescription>
          {
            mapkey.description ? (mapkey.description.length > 30
              ? `${mapkey.description.slice(0, 27)}...`
              : mapkey.description) : 'No Description'
          }
        </MapkeyDescription>
      </MapkeyDetails>
      <Flex alignItems='center' justifyContent='center' mr={4}>
        {
          isVisible && (
            <MapkeyFormField mapkey={mapkey} isPerforming={isPerforming} />
          ) || null
        }
      </Flex>
    </Row>
  );
};

const MapkeyFieldRow = ({ style, ...props }) => (
  <Flex flexDirection='column' style={style}>
    <MapkeyFieldItem { ...props } />
  </Flex>
);

const createSelectMapkeyIds = () => createSelector(
  selectNormalizedMapkeys(),
  (mapkeys) => mapkeys.reduce((output, mapkey) => output.push(mapkey.id), List()),
);

const ConfigProfileMapkeysForm = ({ onSubmit, isFetching, isPerforming, initialValues, height, fetchMapkeys }) => {
  useEffect(() => {
    fetchMapkeys();
  }, []);

  const listRef = useRef();
  const sizeList = useRef(List());

  const renderedItemsRef = useRef({
    // default from the function
    overscanStartIndex: -1,
    overscanStopIndex: -1,
    visibleStartIndex: -1,
    visibleStopIndex: -1,
  });

  const setRenderedItems = useCallback(state => renderedItemsRef.current = state, []);

  const setSize = useCallback((index, size) => {
    // only update sizeList and reset cache if an actual value changed
    if (sizeList.current.get(index) !== size) {
      sizeList.current = sizeList.current.set(index, size);
      if (listRef.current) {
        // clear cache and re-render
        listRef.current.resetAfterIndex(index);
      }
    }
  }, []);

  const getSize = useCallback(index => sizeList.current.get(index) || 120, []);

  // Increases accuracy by calculating an average row height
  // Fixes the scrollbar behaviour described here: https://github.com/bvaughn/react-window/issues/408
  const calcEstimatedSize = useCallback(() => {
    return sizeList.current.reduce((estimatedHeight, itemHeight) => estimatedHeight + itemHeight, 0) / sizeList.current.size;
  }, []);

  const selectMapkeyIds = useMemo(createSelectMapkeyIds, []);
  const mapkeyIds = useSelector(state => selectMapkeyIds(state));

  return (
    <Box as="form" onSubmit={onSubmit}>
      {
        isFetching ? (
          [...Array(5)].map((_, index) => <SkeletonMapkeyFieldRow key={index} index={index} height={120} />)
        ) : (
          <VariableItemSizeList
            ref={listRef}
            height={height - 80}
            itemCount={mapkeyIds.size}
            itemSize={getSize}
            overscanCount={4}
            estimatedItemSize={calcEstimatedSize()}
            onItemsRendered={setRenderedItems}
          >
            {
              ({ index, ...props }) => (
                <MapkeyFieldRow
                  { ...props }
                  index={index}
                  id={mapkeyIds.get(index)}
                  isPerforming={isPerforming}
                  isVisible={index >= renderedItemsRef.current.overscanStartIndex && index <= renderedItemsRef.current.overscanStopIndex}
                  initialValue={initialValues.get(mapkeyIds.get(index))}
                  setSize={setSize}
                />
              )
            }
          </VariableItemSizeList>
        )
      }
    </Box>
  );
};

const enhance = compose(
  reduxForm({
    form: EK.CONFIG_PROFILE_MAPKEYS.state,
  }),
  connect(
    undefined, // mapStateToProps
    dispatch => ({
      fetchMapkeys() { dispatch(processFetchAllMapkeys()); },
    }),
  )
);

export default enhance(ConfigProfileMapkeysForm);
