import { toast } from 'react-toastify';

import EK from '../keys';

import api from '../../modules/api/actions';
import { REQUEST_TYPES } from '../../modules/api/constants';

import {
  fetchAllProfileLibraryTypes,
  createProfileLibraryTypes,
  copyProfileLibraryTypes,
  editProfileLibraryTypes,
  deleteProfileLibraryTypes
} from './constants';
import schema from './schema';

const client = api(schema, EK.PROFILE_LIBRARIES);

export const processFetchAllProfileLibraries = () =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllProfileLibraryTypes,
    '/framework/profile_libraries'
  );

export const processCreateProfileLibrary = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    createProfileLibraryTypes,
    '/framework/profile_libraries',
    {
      data: {
        profileLibraries: [{
          name: data.name,
          folderName: data.folderName,
          description: data.description,
          unitSystemId: data.unitSystemId,
        }],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Profile Library successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create Profile Library',
      },
    }
  );

export const processEditProfileLibrary = (ProfileLibraryId, data) =>
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editProfileLibraryTypes,
    `/framework/profile_libraries/${ProfileLibraryId}`,
    {
      data,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

export const processDeleteProfileLibrary = (ProfileLibraryId) =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteProfileLibraryTypes,
    `/framework/profile_libraries/${ProfileLibraryId}?with_full_detail=true`,
    {
      deleteKey: EK.PROFILE_LIBRARIES.state,
      dependentDeletionKeys: [EK.PROFILES.state],
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Profile Library deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete Profile Library',
      },
    }
  );


//not sure this is necessary, not going to test it or add it until James approves
export const processCopyProfileLibrary = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copyProfileLibraryTypes,
    '/framework/profile_libraries/copy',
    {
      data: {
        profileLibraries: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Layer State successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy Layer State',
      },
    }
  );