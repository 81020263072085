import baseSchema from '../../modules/entities/utils/schema';

import keys from './keys';

import UnitSchema from '../Units/schema';
import UnitSystemSchema from '../UnitSystems/schema';

const materialSchema = baseSchema(keys.state, {
  densityUnit: { schema: UnitSchema, isId: true },
  unitSystem: { schema: UnitSystemSchema, isId: true },
});

export default materialSchema;
