import { createSelector } from 'reselect';

import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';
import { selectCurrentTable } from '../ProfileTables/selectors';

export const selectNormalizedProfileTableColumns = () => createSelector(
  selectEntities(),
  (entities) => entities.get(EK.PROFILE_TABLE_COLUMNS.state)
);

export const selectCurrentTableColumns = () => createSelector(
  selectCurrentTable(),
  selectNormalizedProfileTableColumns(),
  (tableId, columns) => columns.filter(col => col.table == tableId).toList().toArray()
);

export const selectTableColumnsById = (tableId) => createSelector(
  selectNormalizedProfileTableColumns(),
  (columns) => columns.filter( col => col.table == tableId).toList().toArray(),
);