import { createSelector } from 'reselect';

import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';
import { selectCurrentProfileId } from '../Profiles/selectors';

export const selectNormalizedParameters = () => createSelector(
  selectEntities(),
  (entities) => entities.get(EK.PROFILE_PARAMETERS.state)
);
export const selectNormalizedProfileParametersList = () => createSelector(
  selectCurrentProfileId(),
  selectNormalizedParameters(),
  (currentProfileId, parameters) => parameters.filter( param => param.profile == currentProfileId).toList()
);
export const selectNormalizedProfileParametersListById = (profileId) => createSelector(
  selectNormalizedParameters(),
  (parameters) => parameters.filter(param => param.profile == profileId).toList()
);