import { toast } from 'react-toastify';

import EK from '../keys';

import api from '../../modules/api/actions';
import { REQUEST_TYPES } from '../../modules/api/constants';

import {
  fetchAllParameterProfilesTypes,
  fetchIndividualParameterProfileTypes,
  createParameterProfileTypes,
  copyParameterProfileTypes,
  editParameterProfileTypes,
  deleteParameterProfileTypes,
  archiveParameterProfilesTypes,
  unarchiveParameterProfilesTypes,
} from './constants';
import schema from './schema';

const client = api(schema, EK.PARAMETER_PROFILES);

export const processFetchAllParameterProfiles = () => client(REQUEST_TYPES.GET, 'plural', fetchAllParameterProfilesTypes, '/standardize/parameter_profiles?all=true');

export const processFetchIndividualParameterProfile = profileId => client(REQUEST_TYPES.GET, 'single', fetchIndividualParameterProfileTypes, `/standardize/parameter_profiles/${profileId}?with_full_detail=true`, {
  mutateResponse: ({ parameterProfile: { id, parameterProfileParameters : parameters, ...profile } = {} }) => ({
    parameterProfile: {
      ...profile,
      id,
      parameterProfileParameters: parameters.map(pv => ({ ...pv, parameterProfile: `${id}`, parameterProfileId: `${id}` })),
    },
  }),
});

export const processCreateParameterProfile = data => client(REQUEST_TYPES.POST, 'plural', createParameterProfileTypes, '/standardize/parameter_profiles', {
  data: {
    parameterProfiles: [data],
  },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Profile successfully created',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to create profile',
  },
});

export const processCopyParameterProfile = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copyParameterProfileTypes,
    '/standardize/parameter_profiles/copy',
    {
      data: {
        [EK.PARAMETER_PROFILES.plural]: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Parameter profile successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy parameter profile',
      },
    }
  );

export const processEditParameterProfile = (profileId, data) => client(REQUEST_TYPES.PATCH, 'single', editParameterProfileTypes, `/standardize/parameter_profiles/${profileId}`, {
  data,
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});

export const processDeleteParameterProfile = profileId => client(REQUEST_TYPES.DELETE, 'single', deleteParameterProfileTypes, `/standardize/parameter_profiles/${profileId}?with_full_detail=true`, {
  deleteKey: EK.PARAMETER_PROFILES.state,
  dependentDeletionKeys: [EK.PARAMETER_PROFILE_PARAMETERS],
  mutateResponse: ({ parameterProfile: { id, ...other } = {}, parameterProfileParameters = [] }) => ({
    parameterProfile: {
      ...other,
      id,
      parameterProfileParameters: parameterProfileParameters.map(parameter => ({
        ...parameter,
        parameterProfile: profileId,
      })),
    },
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Profile deleted',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to delete profile',
  },
});

export const processArchiveParameterProfiles = profileIds => client(REQUEST_TYPES.ARCHIVE, 'plural', archiveParameterProfilesTypes, '/standardize/parameter_profiles/archive', {
  data: { ids: profileIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Profile archived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to archive profile',
  },
});

export const processUnarchiveParameterProfiles = profileIds => client(REQUEST_TYPES.UNARCHIVE, 'plural', unarchiveParameterProfilesTypes, '/standardize/parameter_profiles/unarchive', {
  data: { ids: profileIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Profile unarchived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to unarchive profile',
  },
});







