import { toast } from 'react-toastify';

import EK from '../keys';

import api from '../../modules/api/actions';
import { REQUEST_TYPES } from '../../modules/api/constants';

import { fetchAllConfigSettingsForCategoryTypes, searchForConfigSettingsTypes } from './constants';
import schema from './schema';

const client = api(schema, EK.CONFIG_SETTINGS);

export const processFetchAllConfigSettingsForCategory = categoryId => client(REQUEST_TYPES.GET, 'plural', fetchAllConfigSettingsForCategoryTypes, `/config_setting_categories/${categoryId}/config_settings`);

export const processSearchForConfigSettings = searchTerm => client(REQUEST_TYPES.POST, 'plural', searchForConfigSettingsTypes, '/config_settings/search', {
  data: { searchTerm },
  mutateResponse: response => ({
    [EK.CONFIG_SETTINGS.plural]: (response[EK.CONFIG_SETTINGS.plural] || []).map(setting => ({ ...setting, incomingSearchTerm: searchTerm }))
  }),
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to complete search',
  },
});
