import { toast } from 'react-toastify';

import EK from '../keys';

import api from '../../modules/api/actions';
import { REQUEST_TYPES } from '../../modules/api/constants';

import {
  fetchAllModelTemplatesTypes,
  fetchIndividualModelTemplateTypes,
  createModelTemplateTypes,
  copyModelTemplateTypes,
  editModelTemplateTypes,
  deleteModelTemplateTypes,
  archiveModelTemplatesTypes,
  unarchiveModelTemplatesTypes,
} from './constants';
import schema from './schema';

const client = api(schema, EK.MODEL_TEMPLATES);

export const processFetchAllModelTemplates = () =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllModelTemplatesTypes,
    '/standardize/model_templates?all=true'
  );

export const processFetchIndividualModelTemplate = (
  modelTemplateId
) =>
  client(
    REQUEST_TYPES.GET,
    'single',
    fetchIndividualModelTemplateTypes,
    `/standardize/model_templates/${modelTemplateId}?with_full_detail=true`
  );

export const processCreateModelTemplate = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    createModelTemplateTypes,
    '/standardize/model_templates',
    {
      data: {
        [EK.MODEL_TEMPLATES.plural]: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Template successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create model template',
      },
    }
  );

export const processCopyModelTemplate = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copyModelTemplateTypes,
    '/standardize/model_templates/copy',
    {
      data: {
        [EK.MODEL_TEMPLATES.plural]: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Template successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy model template',
      },
    }
  );

export const processEditModelTemplate = (modelTemplateId, data) =>
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editModelTemplateTypes,
    `/standardize/model_templates/${modelTemplateId}`,
    {
      data,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

export const processDeleteModelTemplate = (modelTemplateId) =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteModelTemplateTypes,
    `/standardize/model_templates/${modelTemplateId}?with_full_detail=true`,
    {
      deleteKey: EK.MODEL_TEMPLATES.state,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Template deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete model template',
      },
    }
  );

export const processArchiveModelTemplates = (modelTemplateIds) =>
  client(
    REQUEST_TYPES.ARCHIVE,
    'plural',
    archiveModelTemplatesTypes,
    '/standardize/model_templates/archive',
    {
      data: { ids: modelTemplateIds },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Template archived',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to archive model template',
      },
    }
  );

export const processUnarchiveModelTemplates = (modelTemplateIds) =>
  client(
    REQUEST_TYPES.UNARCHIVE,
    'plural',
    unarchiveModelTemplatesTypes,
    '/standardize/model_templates/unarchive',
    {
      data: { ids: modelTemplateIds },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Template unarchived',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to unarchive model template',
      },
    }
  );
