import { toast } from 'react-toastify';

import EK from '../keys';

import api from '../../modules/api/actions';
import { REQUEST_TYPES } from '../../modules/api/constants';

import {
  createSimplifiedRepRulesetRuleTypes,
  editSimplifiedRepRulesetRuleTypes,
  deleteSimplifiedRepRulesetRuleTypes,
} from './constants';
import schema from './schema';

const client = api(schema, EK.SIMPLIFIED_REP_RULESET_RULES);

export const processCreateSimplifiedRepRulesetRule = (
  simplifiedRepId,
  simplifiedRepRulesetId,
  data
) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    createSimplifiedRepRulesetRuleTypes,
    `/simplified_reps/${simplifiedRepId}/simplified_rep_rulesets/${simplifiedRepRulesetId}/simplified_rep_ruleset_rules`,
    {
      data: {
        simplifiedRepRulesetRules: [data],
      },
      mutateResponse: ({
        simplifiedRepRuleset = {},
        simplifiedRepRulesetRules = [],
      }) => ({
        simplifiedRepRuleset: {
          ...simplifiedRepRuleset,
          simplifiedRepRulesetRules: (
            simplifiedRepRuleset.simplifiedRepRulesetRules ||
            []
          ).map((rule) => ({
            ...rule,
            simplifiedRepRulesetId: `${simplifiedRepRuleset.id}`,
          })),
        },
        simplifiedRepRulesetRules: simplifiedRepRulesetRules.map(
          (rule) => ({
            ...rule,
            simplifiedRepRulesetId: `${simplifiedRepRuleset.id}`,
          })
        ),
      }),
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Rule successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create rule',
      },
    }
  );

export const processEditSimplifiedRepRulesetRule = (
  simplifiedRepId,
  simplifiedRepRulesetId,
  simplifiedRepRulesetRuleId,
  data
) =>
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editSimplifiedRepRulesetRuleTypes,
    `/simplified_reps/${simplifiedRepId}/simplified_rep_rulesets/${simplifiedRepRulesetId}/simplified_rep_ruleset_rules/${simplifiedRepRulesetRuleId}`,
    {
      data,
      mutateResponse: ({
        simplifiedRepRuleset = {},
        simplifiedRepRulesetRule = {},
      }) => ({
        simplifiedRepRuleset,
        simplifiedRepRulesetRule: {
          ...simplifiedRepRulesetRule,
          simplifiedRepRulesetId: `${simplifiedRepRuleset.id}`,
        },
      }),
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

export const processDeleteSimplifiedRepRulesetRule = (
  simplifiedRepId,
  simplifiedRepRulesetId,
  simplifiedRepRulesetRuleId
) =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteSimplifiedRepRulesetRuleTypes,
    `/simplified_reps/${simplifiedRepId}/simplified_rep_rulesets/${simplifiedRepRulesetId}/simplified_rep_ruleset_rules/${simplifiedRepRulesetRuleId}`,
    {
      deleteKey: EK.SIMPLIFIED_REP_RULESET_RULES.state,
      dependentDeletionKeys: [
        EK.SIMPLIFIED_REP_RULESET_RULE_CRITERION_VALUES.state,
      ],
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Rule deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete rule',
      },
    }
  );
