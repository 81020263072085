import React from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import BreadcrumbTemplate from '../../templates/Structures/Breadcrumb';
import { useSelector } from 'react-redux';

import { PipingModuleProjectsEditPath } from '../../../paths';
import { selectCurrentProject } from '../../../entities/Projects/selectors';

function ProjEditBreadcrumb({ location }) {
  const match = matchPath(location.pathname, { path: PipingModuleProjectsEditPath.url });
  const projectId = (match && match.params.id) || null;
  const project = useSelector(selectCurrentProject(projectId));

  return <BreadcrumbTemplate title={(project && project.name) || PipingModuleProjectsEditPath.defaultTitle} />;
}

export default withRouter(ProjEditBreadcrumb);