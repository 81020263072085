import { List } from 'immutable';

import EntityRecord, { FILTERS } from '../../modules/entities/model';

export const ALLOWANCES = {
  NAME: /^([a-zA-Z0-9_-]{0,31})$/,
};

// prettier-ignore
export default class Profile extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  description: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  fileName: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  profilePrimaryTableId: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
  },
  unitSystem: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'unitSystemId',
  },
  profileLibrary: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'profileLibraryId',
  }
}) {}
