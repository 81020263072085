import React, { useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';

import CreateOrEditEntityTitle from '../../../../components/modal/templates/CreateOrEditEntityTitle';

import ConfirmDeleteForm from '../../../form/templates/ConfirmDeleteForm';

import ParameterProfileForm from '../../../form/templates/ParameterProfileForm';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import ParameterProfileModel from '../../../../entities/ParameterProfiles/model';
import {
  processCreateParameterProfile,
  processCopyParameterProfile,
  processEditParameterProfile,
  processDeleteParameterProfile,
} from '../../../../entities/ParameterProfiles/actions';

import { selectCurrentParameterProfile } from './selectors';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

const emptyParameterProfile = new ParameterProfileModel();

const ParameterProfileModal = ({ parameterProfile, copyEntity, isPerforming, onCreate, onCopy, onUpdate, onDelete }) => {
  const onSave = useCallback(data => {
    if (parameterProfile) {
      if (copyEntity) {
        onCopy({ ...data, id: parameterProfile.id });
      } else {
        onUpdate(parameterProfile.id, data);
      }
    } else {
      onCreate(data);
    }
  }, [parameterProfile, copyEntity, onCreate, onCopy, onUpdate]);

  const onConfirmDelete = useCallback(() => onDelete(parameterProfile.id), [parameterProfile, onDelete]);

  return (
    <Box>
      <CreateOrEditEntityTitle edit={parameterProfile && parameterProfile.name} copy={copyEntity} create='Parameter Profile' />

      <ParameterProfileForm
        initialValues={parameterProfile || emptyParameterProfile}
        isPerforming={isPerforming}
        enableReinitialize={copyEntity}
        isCopying={copyEntity}
        onRDXSubmit={onSave}
      />

      {
        parameterProfile && !copyEntity &&
        <React.Fragment>
          <Box pb={4} />
          <ConfirmDeleteForm deletePhrase={parameterProfile.name} isPerforming={isPerforming} onRDXSubmit={onConfirmDelete} />
        </React.Fragment>
      }
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerformingInModal(),
  parameterProfile: selectCurrentParameterProfile(),
});

const enhance = compose(
  injectSaga({ key: `${EK.PARAMETER_PROFILES.state}Modal`, saga }),
  connect(
    mapStateToProps,
    {
      onCreate: processCreateParameterProfile,
      onCopy: processCopyParameterProfile,
      onUpdate: processEditParameterProfile,
      onDelete: processDeleteParameterProfile,
    }
  ),
);

export default enhance(ParameterProfileModal);
