import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import EK from '../../../../entities/keys';

import { DEFAULT_ACTION_OPTIONS } from '../../../../entities/SimplifiedReps/model';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';

import BaseFormSection from '../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../components/form/BaseFormRow';

import RDXTextInput from '../../RDXTextInput';
import RDXRadioSelect from '../../RDXRadioSelect';

import { ALLOWANCES } from '../../../../entities/SimplifiedReps/model';
import { validations } from '../../../../entities/SimplifiedReps/formValidation';

const SimplifiedRepForm = ({
  handleSubmit,
  isPerforming,
  valid,
}) => (
  <Box as="form" onSubmit={handleSubmit}>
    <BaseFormSection>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name="name"
          label="Name"
          required
          allow={ALLOWANCES.NAME}
          validate={validations['name']}
          width="70%"
        />
        <Field
          component={RDXRadioSelect}
          name="creoAction"
          label="Default Action"
          options={DEFAULT_ACTION_OPTIONS}
          required
          validate={validations['creoAction']}
          width="30%"
        />
      </BaseFormRow>

      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name="description"
          label="Description"
        />
      </BaseFormRow>
    </BaseFormSection>

    <Button
      primary
      type="submit"
      large
      full
      disabled={isPerforming || !valid}
      isPerformingAction={isPerforming}
    >
      Save
    </Button>
  </Box>
);

const enhance = compose(
  reduxForm({
    form: EK.SIMPLIFIED_REPS.state,
    onSubmit(model, dispatch, props) {
      props.onRDXSubmit(model.serialize());
    },
  })
);

export default enhance(SimplifiedRepForm);
