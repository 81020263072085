import { List } from 'immutable';

import EntityRecord, { FILTERS } from '../../modules/entities/model';

// prettier-ignore
export default class ProfileDimension extends EntityRecord({
  dimensionName: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  isShownDimension: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.BOOLEAN
  },
  isInputDimension: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.BOOLEAN
  },
  defaultValue: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
  },
  profile: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'profileId',
  },
  rowType: {
    value: 'Dimension',
    handleCopy: false,
    handleCompare: false,
    handleSerialize: false,
  }
}) {}
