import baseSchema from '../../modules/entities/utils/schema';

import EK from '../keys';

import SimplifiedRepRulesetRuleSchema from '../SimplifiedRepRulesetRules/schema';

// prettier-ignore
const parameterSimplifiedRepRulesetSchema = baseSchema(
  EK.SIMPLIFIED_REP_RULESETS.state,
  {
    simplifiedRepRulesetRules: {
      schema: [SimplifiedRepRulesetRuleSchema],
      isId: true,
      idField: 'simplifiedRepRulesetRuleIds',
      isList: true,
    },
  }
);

export default parameterSimplifiedRepRulesetSchema;
