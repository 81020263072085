import { toast } from 'react-toastify';

import EK from '../keys';

import api from '../../modules/api/actions';
import { REQUEST_TYPES } from '../../modules/api/constants';

import {
  fetchAllStyleStateTypes,
  createStyleStateTypes,
  copyStyleStateTypes,
  editStyleStateTypes,
  deleteStyleStateTypes
} from './constants';
import schema from './schema';

const client = api(schema, EK.STYLE_STATES);

export const processFetchAllStyleStates = () =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllStyleStateTypes,
    '/standardize/style_states'
  );

export const processCreateStyleState = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    createStyleStateTypes,
    '/standardize/style_states',
    {
      data: {
        styleStates: [{
          name: data.name,
          description: data.description
        }],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Style State successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create Style State',
      },
    }
  );

export const processEditStyleState = (StyleStateId, data) =>
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editStyleStateTypes,
    `/standardize/style_states/${StyleStateId}`,
    {
      data,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

export const processDeleteStyleState = (StyleStateId) =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteStyleStateTypes,
    `/standardize/style_states/${StyleStateId}?with_full_detail=true`,
    {
      deleteKey: EK.STYLE_STATES.state,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Style State deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete Style State',
      },
    }
  );


//not sure this is necessary, not going to test it or add it until James approves
export const processCopyStyleState = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copyStyleStateTypes,
    '/standardize/style_states/copy',
    {
      data: {
        styleStates: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Style State successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy Style State',
      },
    }
  );