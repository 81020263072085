import { List } from 'immutable';
import Color from 'color';

import EntityRecord, { VALIDATORS, COMPARATORS } from '../../modules/entities/model';

export const ALLOWANCES = {
  NAME: /^(.{0,255})$/,
};

class Appearance extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      VALIDATORS.IS_REQUIRED_STRING(entity.name) &&
      entity.name.length > 0 &&
      ALLOWANCES.NAME.test(entity.name),
    validateError: 'Name must be present and at most 255 characters in length',
  },
  color: {
    value: null,
    handleCopy: true,
    handleCompare: (current, incoming) =>
      COMPARATORS.COLOR(current.color, incoming.color),
    handleSerialize: (entity) =>
      (!entity.isCreo && `[${Color.rgb(entity.color).array()}]`) || null,
    validate: (entity) => {
      if (entity.isCreo) {
        return entity.color === null || entity.color == undefined;
      } else {
        if (entity.color === null || entity.color === undefined) {
          return false;
        }

        return VALIDATORS.IS_RGB_COLOR_OBJECT(entity.color);
      }
    },
    validateError: (entity) => {
      if (entity.isCreo) {
        return 'Creo colors cannot have colors here';
      } else {
        return 'Custom colors must have defined colors here to be saved';
      }
    },
  },
  isCreo: {
    value: false,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
  },
}) {
  constructor({ color, ...other } = {}) {
    super({
      color:
        (typeof color === 'string' && Color.rgb(JSON.parse(color)).object()) ||
        color,
      ...other,
    });
  }

  get primary() {
    return this.name;
  }
  get primaryPlaceholder() {
    return 'No Name';
  }
  get secondary() {
    return this.isCreo ? 'Creo Color' : 'User-Defined Color';
  }
  get secondaryPlaceholder() {
    return '--';
  }
  get tertiary() {
    return this.color;
  }
}

export default Appearance;
