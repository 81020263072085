import { compose } from 'redux';
import { List } from 'immutable';

import EntityRecord, { VALIDATORS, FILTERS } from '../../modules/entities/model';
import hasMeasurements from '../../modules/entities/decorators/hasMeasurements';

class BendMachineInstance extends EntityRecord({
  sizeProperty: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'sizeId',
    validate: entity => VALIDATORS.IS_REQUIRED_ENTITY(entity.sizeProperty),
    validateError: 'A valid size must be present',
    filter: FILTERS.ENTITY,
  },
  startClampLength: {
    value: List(),
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    isUnit: true,
    unitType: 'unitLength',
    validate: entity => VALIDATORS.IS_REQUIRED_VALID_UNIT(entity.startClampLength),
    validateError: 'A numeric start clamp length must be present',
  },
  midClampLength: {
    value: List(),
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    isUnit: true,
    unitType: 'unitLength',
    validate: entity => VALIDATORS.IS_REQUIRED_VALID_UNIT(entity.midClampLength),
    validateError: 'A numeric mid clamp length must be present',
  },
  endClampLength: {
    value: List(),
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    isUnit: true,
    unitType: 'unitLength',
    validate: entity => VALIDATORS.IS_REQUIRED_VALID_UNIT(entity.endClampLength),
    validateError: 'A numeric end clamp length must be present',
  },
  bendMachineFamily: {
    value: null,
    isEntity: true,
    handleCopy: true,
  },
  bendMachineFamilyId: {
    value: null,
    handleCopy: true,
  },
}) {}

const enhance = compose(
  hasMeasurements
);

export default enhance(BendMachineInstance);
