import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

// Grid Imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

// Hooks
import { useOnCellEditComplete } from '../../../hooks/useOnCellEditComplete';

// cell bodies/editors
import EntityRowTools from '../../../components/common/EntityTools/RowStartTools';
import Text from '../../../components/common/Text';
import { InputText } from 'primereact/inputtext';
import colors from '../../../assets/themes/base/colors';
import Button from '../../../components/common/Button';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';

//misc
import { Dialog } from 'primereact/dialog';

// Header
import Flex from '../../../components/common/Flex';
import {
  PipingModuleProjectsEditPath
} from '../../../paths';
import Header from '../../templates/Structures/Header';
import Options from '../../templates/Structures/Options';
import Skeleton from 'react-loading-skeleton';
import { pluralize } from '../../../components/common/Header';

// dropdown constants and validation
import { rowStyles } from '../../../components/primeGrid/RowStyles';

// Actions
import { saveCurrentProjectSpecs } from './actions';
import { convertToProjectSpecType } from './actions';
import { processFetchAllSpecs } from '../../../entities/Specs/actions';
import { processArchiveProjects, processEditProject, processFetchIndividualProject, processSaveProjectSpecs, processUnarchiveProjects } from '../../../entities/Projects/actions';

// selectors
import {
  selectPipingModulePermissionsAndState,
  selectSidebarIsFiltering,
} from '../../Dashboard/selectors';
import { selectCurrentFilteredProjectSpecInstances, selectCurrentProject, selectProjectSpecInstanceCount } from '../../../entities/Projects/selectors';
import { selectSpecsCounts } from '../../../entities/Specs/selectors';
import DialogFooter from '../../../components/modal/ModalFooters/DialogFooter';

const mapStateToProps = (projectId) => createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentProject(projectId),
  selectCurrentFilteredProjectSpecInstances(projectId),
  selectProjectSpecInstanceCount(projectId),
  selectSpecsCounts(),
  selectSidebarIsFiltering(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    project,
    data,
    instanceCount,
    {
      unarchived,
      total
    },
    isFiltering,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || instanceCount == 0));
    return {
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense && !project?.archivedFlag,
      canCollaborate,
      hasValidLicense,
      instanceCount,
      project,
      isFiltering,
      totalSpecCount: total,
    };
  },
);

function PipesEditGrid({ match }) {
  const reduxProps = useSelector(mapStateToProps(match.params.id));
  const dispatch = useDispatch();

  // table state
  const [editedRows, resetEditedRows, currentEditingRow, isEditing, onBeforeCellEditShow, onCellChange, onCellEditComplete, handleRowAction, onDropdownComplete] = useOnCellEditComplete(reduxProps.data, convertToProjectSpecType, reduxProps.editable, true);

  //Dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState({id: '', name: ''});
  const [validation, setValidation] = useState({name: true});

  const openDialog = () => {
    setCurrentProject({
      id: reduxProps.project.id,
      name: reduxProps.project.name
    });
    setValidation({name: true});
    setIsDialogOpen(true);
  };

  const saveDialogChanges = () => {
    if(!currentProject.name) {
      setValidation({name: false});
      return;
    }
    setIsDialogOpen(false);
    dispatch(processEditProject(match.params.id, currentProject));
  };

  const handleDialogEdit = (newValue, field) => {
    setCurrentProject((old) => {
      let _editedRow = { ...old };
      _editedRow[`${field}`] = newValue;
      return _editedRow;
    });
  };

  //Cell Bodies
  const nmSummary = (curRow) => {
    if(curRow.archivedFlag) {
      return curRow.mnemonic && `${curRow.specName} / ${curRow.mnemonic} (archived)` || `${curRow.specName} (archived)`;
    }
    if ( curRow.mnemonic ) {
      return `${curRow.specName} / ${curRow.mnemonic}`;
    }
    return curRow.specName;
  };

  const summaryBody = (rowdata) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToProjectSpecType(rowdata);

    return curRow &&
      <ComplexCellRenderer value={{
        primary: nmSummary(curRow),
        secondary: curRow.instancesCount || '--'
      }} isCapitalized={true} paddingLeft='0px' /> ||
      <Text className='text-cell-body' style={{ color: colors.gray[5] }}>Summary Not Available</Text>;
  };

  // Cell Editors
  const instanceSelector = (rowdata) => {
    const curRow = editedRows && editedRows.find(item => item.id == rowdata.id) || convertToProjectSpecType(rowdata);
    if(!curRow.archivedFlag) {
      return <Button icon={curRow.isSelectedForProject ? 'checkbox' : 'checkbox-outline'} iconFillColor={curRow.isSelectedForProject ? 'primary.4' : 'gray.6'} onClick={() => !rowdata.archivedFlag && onDropdownComplete.current(!curRow.isSelectedForProject, 'isSelectedForProject', rowdata)} />;
    }
  };

  useEffect(() => {
    dispatch(processFetchAllSpecs());
    dispatch(processFetchIndividualProject(match.params.id));
  }, []);

  return (
    <>
      {!reduxProps.isLoading && <Flex flexDirection="row" mb={4} >
        <Header
          isLoading={reduxProps.isLoading}
          title={reduxProps.project?.name || PipingModuleProjectsEditPath.defaultTitle}
          subtitle={reduxProps.isFiltering ?
            `Showing ${reduxProps.data.length}/${reduxProps.totalSpecCount} ${pluralize('total Spec', reduxProps.totalSpecCount)}` :
            `Showing ${reduxProps.data.length} ${pluralize('total Spec', reduxProps.data.length)} (${reduxProps.instanceCount || 0} selected for project)`}
        />
        <Options
          updateEntities={() => saveCurrentProjectSpecs(editedRows, reduxProps.project, (data) => { dispatch(processSaveProjectSpecs(match.params.id, data)); resetEditedRows(); })}
          editEntity={openDialog}
          isArchived={reduxProps.project && reduxProps.project.archivedFlag}
          archiveEntity={() => dispatch(processArchiveProjects([match.params.id]))}
          unarchiveEntity={() => dispatch(processUnarchiveProjects([match.params.id]))}
          isLoading={reduxProps.isLoading}
          pendingValidChanges={editedRows.length > 0}

          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
          canFilter={true}
        />
      </Flex> || <Skeleton style={{ height: '2rem', marginBottom: '1rem' }} />
      }
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          value={reduxProps.data}
          tableStyle={{ minWidth: '55rem' }}
          size='normal'
          editMode='cell'
          rowClassName={(data) => data && rowStyles(data, editedRows)}

          scrollable
          scrollHeight='flex'

          removableSort
        >
          <Column header='' style={{ width: '2%' }} body={(rowdata) => instanceSelector(rowdata)} ></Column>
          <Column field='id' header='Name + Mnemonic // Instances' body={(rowdata) => summaryBody(rowdata)} ></Column>
        </DataTable>
      </Flex>
      <Dialog visible={reduxProps.editable && isDialogOpen} style={{ width: '75%' }} header={`Edit ${reduxProps.project.name}`} footer={() => DialogFooter(false, false, false, saveDialogChanges, () => setIsDialogOpen(false))} modal className='p-fluid' closable={false} >
        <div>
          <label>Name</label>
          <InputText value={currentProject.name} onChange={(e) => handleDialogEdit(e.target.value, 'name')} className={!validation.name && 'p-invalid'} />
        </div>
      </Dialog>
    </>
  );
}

export default withRouter(PipesEditGrid);