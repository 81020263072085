import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Box from '../../../../components/common/Box';

import ModalTitle from '../../../../components/modal/templates/ModalTitle';

import MasterCatalogUnitSystemForm from '../../../form/templates/MasterCatalogUnitSystemForm';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import { processUpdateMasterCatalog } from '../../../../entities/PipingCatalogs/actions';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

const MasterCatalogUnitSystemModal = ({ isPerforming, updateDefaultUnitSystem }) => {
  return (
    <Box>
      <ModalTitle><strong>Edit</strong> Default Unit System</ModalTitle>

      <MasterCatalogUnitSystemForm
        isPerforming={isPerforming}
        onRDXSubmit={updateDefaultUnitSystem}
      />
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerformingInModal(),
});

const enhance = compose(
  injectSaga({ key: 'masterCatalogUnitSystemModal', saga }),
  connect(
    mapStateToProps,
    { updateDefaultUnitSystem: processUpdateMasterCatalog }
  ),
);

export default enhance(MasterCatalogUnitSystemModal);
