import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = 'EXPLODE_STATES';

export const fetchAllExplodeStateTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createExplodeStateTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const copyExplodeStateTypes = generateAPHTypes(
  resource,
  'COPY'
);

export const editExplodeStateTypes = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteExplodeStateTypes = generateAPHTypes(
  resource,
  'DELETE'
);