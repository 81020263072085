import { createSelector } from 'reselect';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

import { newAppearanceRows } from './constants';
import { List } from 'immutable';

export const selectNormalizedAppearances = () => createSelector(
  selectEntities(),
  entities => entities.get('appearances')
);
export const selectAppearancesOptions = () => createSelector(
  selectNormalizedAppearances(),
  appearances => appearances.map(appearance => appearance.toOption()).toList()
);

//maps the complicated entity to a more simple object
export const selectNormalizedAppearancesArray = () => createSelector(
  selectEntities(),
  entities => entities.get('appearances').toList().toArray().map( (row) => ({
    id: row.id,
    name: row.name,
    color: row.color,
    isCreo: row.isCreo,
    canSafelyDelete: row.canSafelyDelete,
    isDeleted: false,
    isCreated: false,
    isEdited: false,
  })).concat(newAppearanceRows)
);