import baseSchema from '../../modules/entities/utils/schema';

import EK from '../keys';

// prettier-ignore
const explodeStateSchema = baseSchema(
  EK.EXPLODE_STATES.state
);

export default explodeStateSchema;
