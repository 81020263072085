import EntityRecord from '../../modules/entities/model';

class ConfigProfileMapkey extends EntityRecord({
  // N.B.: ConfigProfileMapkeys are only maintained in state within the context of a configProfile
  //   configProfile is also not returned in the ConfigProfileMapkey object by the API.
  //   Therefore this is deprecated.
  // configProfile: {
  //   value: null,
  //   isEntity: true,
  //   handleCopy: true,
  //   handleCompare: true,
  //   handleSerialize: true,
  //   handleDeepSerialize: true,
  //   serializeOutputField: 'configProfileId',
  // },
  mapkey: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    handleDeepSerialize: true,
    serializeOutputField: 'mapkeyId',
  },
}) {
  get primary() {
    return this.mapkey && this.mapkey.name;
  }
  get primaryPlaceholder() {
    return 'No Mapkey Name';
  }
  get secondary() {
    return this.mapkey && this.mapkey.description;
  }
  get secondaryPlaceholder() {
    return 'No Mapkey Description';
  }
}

export default ConfigProfileMapkey;
