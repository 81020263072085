import generateAPHTypes from '../../utils/constants/generateAPHTypes';

const resource = 'SIMPLIFIED_REP_RULESETS';

export const fetchAllSimplifiedRepRulesetsForIndividualSimplifiedRepTypes = generateAPHTypes(
  resource,
  'FETCH_ALL'
);

export const createSimplifiedRepRulesetTypes = generateAPHTypes(
  resource,
  'CREATE'
);

export const editSimplifiedRepRulesetTypes = generateAPHTypes(
  resource,
  'EDIT'
);

export const deleteSimplifiedRepRulesetTypes = generateAPHTypes(
  resource,
  'DELETE'
);
