import { toast } from 'react-toastify';

import specPipeInstanceClient from './client';

import { REQUEST_TYPES } from '../../modules/api/constants';

import { saveSpecPipeInstancesTypes } from './constants';

export const processSaveSpecPipeInstances = (specId, data) => specPipeInstanceClient(REQUEST_TYPES.PATCH, 'plural', saveSpecPipeInstancesTypes, `/piping/specs/${specId}?`, {
  data: { specPipeInstancesAttributes: data },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});