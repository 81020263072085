import { toast } from 'react-toastify';

import EK from '../keys';

import api from '../../modules/api/actions';
import { REQUEST_TYPES } from '../../modules/api/constants';

import {
  fetchAllTableTypes,
  createTableTypes,
  copyTableTypes,
  editTableTypes,
  deleteTableTypes
} from './constants';
import schema from './schema';

const client = api(schema, EK.PROFILE_TABLES);

const mutateTablesData = (profileTables, profileId) => {
  // mutates data and oraganizes what tables are a subtable
  let subtablesList = [];
  let tables = profileTables.map((table) => {
    const subtableItems = table.profileTableItems && table.profileTableItems.filter(item => item.valueType == 'subtable');
    subtableItems && subtableItems.forEach((item) => subtablesList.push({childId: item.profileSubtableId, parentId: table.id }));
    return {...table, profile: profileId};
  });
  subtablesList.length > 0 && subtablesList.forEach( subtable => {
    let curTable = tables.find(table => table.id == subtable.childId);
    curTable.parentTable = subtable.parentId;
    curTable.isSubtable = true;
  });
  return {profileTables: tables};
};

// # GET /api/framework/profiles/:profile_id/profile_tables
export const processFetchTables = (profileId) =>
  client(
    REQUEST_TYPES.GET,
    'plural',
    fetchAllTableTypes,
    `/framework/profiles/${profileId}/profile_tables?with_full_detail=true`,
    {
      mutateResponse: ( {profileTables = []} ) => mutateTablesData(profileTables, profileId)
    },
  );

// # POST /api/framework/profiles/:profile_id/profile_tables
export const processCreateTable = (profileId, newTables) =>
  /// newTables must be an array of table objects. (even if it's only one)
  client(
    REQUEST_TYPES.POST,
    'plural',
    createTableTypes,
    `/framework/profiles/${profileId}/profile_tables`,
    {
      data: {
        profile_tables: newTables,
      },
      mutateResponse: ({ profileTables = [] }) => ({
        profileTables: profileTables.map(dim => ({ ...dim, profile: profileId, })),
      }),
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Table successfully created',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to create Table',
      },
    }
  );

// # PATCH /api/framework/profiles/:profile_id/profile_tables/:id
export const processEditTable = (profileId, data) =>
  // data is a profile object containing any/all fields to be changed (not technically necessary to include all fields)
  client(
    REQUEST_TYPES.PATCH,
    'single',
    editTableTypes,
    `/framework/profiles/${profileId}/profile_tables/${data.id}`,
    {
      data,
      mutateResponse: ({ profileTable = {} }) => ({
        profileTable: { ...profileTable, profile: profileId, },
      }),
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Changes saved',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to save changes',
      },
    }
  );

// # DELETE /api/framework/profiles/:profile_id/profile_tables/:id
export const processDeleteTable = (ProfileId, TableId) =>
  client(
    REQUEST_TYPES.DELETE,
    'single',
    deleteTableTypes,
    `/framework/profiles/${ProfileId}/profile_tables/${TableId}?with_full_detail=true`,
    {
      deleteKey: EK.PROFILE_TABLES.state,
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Table deleted',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to delete Table',
      },
    }
  );


//not implemented, not as useable for the modla version of creating items, but
export const processCopyTable = (data) =>
  client(
    REQUEST_TYPES.POST,
    'plural',
    copyTableTypes,
    `/framework/profiles/${ProfileId}/profile_tables/copy`,
    {
      data: {
        tables: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Table successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy Table',
      },
    }
  );